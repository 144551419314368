import { app } from './appPixi.js'
import {
  settings
} from './settings.js'

import * as PIXI from 'pixi.js'

let uiCreated = false

const appWidth = app.screen.width
const appHeight = app.screen.height

const appAspectRatioX = appWidth / 1920
const appAspectRatioY = appHeight / 1080

const rouletteNumbers = [0, 32, 15, 19, 4, 21, 2, 25, 17, 34, 6, 27, 13, 36, 11, 30, 8, 23, 10, 5, 24, 16, 33, 1, 20,
  14, 31, 9, 22, 18, 29, 7, 28, 12, 35, 3, 26
]

let currentRatio = 0

const gameInitialValues = {}

// const gameValues = {
//   ...gameInitialValues
// }

const containers = {
  rootContainer: new PIXI.Container(),
  rouletteContainer: new PIXI.Container(),
  rouletteGraphicContainer: new PIXI.Container(),
  rouletteTableContainer: new PIXI.Container(),
  rouletteBallContainer: new PIXI.Container()
}

const gameAssets = {

  rouletteWheel: {
    Base: new PIXI.Graphics(),
    Mask: new PIXI.Graphics(),
    Cover: new PIXI.Graphics(),
    Handle: new PIXI.Sprite(),
    Ball: new PIXI.Graphics()
  },

  rouletteTable: {
    button: {
      text: new PIXI.Text('', settings.proximaNovaLight),
      color: 0xFFFFFF
    }
  },

  rouletteImg: new PIXI.Sprite()
}

function createUI () {
  if (uiCreated) return
  uiCreated = true
  appAspectRatioX >= appAspectRatioY ? currentRatio = appAspectRatioY : currentRatio = appAspectRatioX

  setupContainers()
  setupGameAssets()

  console.log('UI Created = ' + currentRatio)
  console.log(appAspectRatioX + ' || ' + appAspectRatioY)
}

function setupContainers () {
  app.stage.addChild(containers.rootContainer)
  containers.rootContainer.sortableChildren = true
  containers.rootContainer.zIndex = 0

  containers.rootContainer.addChild(containers.rouletteContainer)
  containers.rouletteContainer.addChild(containers.rouletteGraphicContainer)
  containers.rootContainer.addChild(containers.rouletteTableContainer)
  containers.rouletteContainer.addChild(containers.rouletteBallContainer)

  containers.rootContainer.position.set(appWidth / 2, appHeight / 2)
  // containers.rootContainer.scale.set(currentRatio*1.6)
}

function setupGameAssets () {
  setupRoulette()
  // setupRouletteTable()
}

function setupRoulette () {
  gameAssets.rouletteWheel.Ball.lineStyle(0)
  gameAssets.rouletteWheel.Ball.beginFill(0xFFFFFF, 1)
  gameAssets.rouletteWheel.Ball.drawCircle(0, 0, 6)
  gameAssets.rouletteWheel.Ball.endFill()
  gameAssets.rouletteWheel.Ball.position.set(167, 0)
  gameAssets.rouletteWheel.Ball.visible = false
  containers.rouletteBallContainer.addChild(gameAssets.rouletteWheel.Ball)

  gameAssets.rouletteWheel.Base.lineStyle(0)
  gameAssets.rouletteWheel.Base.beginFill(0x0B0A0A, 1)
  gameAssets.rouletteWheel.Base.drawCircle(0, 0, 180)
  gameAssets.rouletteWheel.Base.endFill()
  containers.rouletteGraphicContainer.addChild(gameAssets.rouletteWheel.Base)

  gameAssets.rouletteWheel.Mask.lineStyle(60, 0x3500fa, 1)
  gameAssets.rouletteWheel.Mask.beginFill(0x3500fa, 0)
  gameAssets.rouletteWheel.Mask.drawCircle(0, 0, 125)
  gameAssets.rouletteWheel.Mask.endFill()
  containers.rouletteGraphicContainer.addChild(gameAssets.rouletteWheel.Mask)

  const trianglewidth = 13.3
  const triangleheight = 155

  for (let i = 1; i <= 37; i++) {
    const triangle = new PIXI.Graphics()
    containers.rouletteGraphicContainer.addChild(triangle)

    if (i % 2 === 0) {
      triangle.lineStyle(0)
      triangle.beginFill(settings.color.red, 2)
    } else {
      triangle.lineStyle(0)
      triangle.beginFill(settings.color.black, 1)
    }

    if (i === 1) {
      triangle.lineStyle(0)
      triangle.beginFill(settings.color.neutral, 1)
    }

    triangle.moveTo(-1 * trianglewidth, triangleheight)
    triangle.lineTo(0, 0)
    triangle.lineTo(trianglewidth, triangleheight)
    triangle.rotation = i * 0.17
    triangle.closePath()
    triangle.endFill()
    triangle.visible = false

    triangle.mask = gameAssets.rouletteWheel.Mask

    const rouletteText = new PIXI.Text(rouletteNumbers[i - 1], settings.proximaNovaLight)
    triangle.addChild(rouletteText)
    rouletteText.anchor.set(0.5)
    rouletteText.angle = 180
    rouletteText.position.set(0, 140)
    rouletteText.scale.set(0.15)
  }

  gameAssets.rouletteWheel.Cover.lineStyle(50, 0x041a22, 0.3)
  gameAssets.rouletteWheel.Cover.beginFill(0x041a22, 0)
  gameAssets.rouletteWheel.Cover.drawCircle(0, 0, 105)
  gameAssets.rouletteWheel.Cover.endFill()
  containers.rouletteGraphicContainer.addChild(gameAssets.rouletteWheel.Cover)

  gameAssets.rouletteImg = spriteSettings(gameAssets.rouletteImg, PIXI.Assets.get('roulette'), containers.rouletteGraphicContainer)
  gameAssets.rouletteImg.rotation = 4.1
  gameAssets.rouletteImg.scale.set(0.26)
  gameAssets.rouletteImg.alpha = 1
  gameAssets.rouletteWheel.Handle.position.set(0, 0)

  gameAssets.rouletteWheel.Handle = spriteSettings(gameAssets.rouletteWheel.Handle, PIXI.Assets.get('handle'), containers.rouletteGraphicContainer)
  gameAssets.rouletteWheel.Handle.scale.set(0.83)
  gameAssets.rouletteWheel.Handle.position.set(0, 0)
  gameAssets.rouletteWheel.Handle.visible = false

  containers.rouletteContainer.scale.set(3 * appAspectRatioY)
  containers.rouletteContainer.position.set(0, 0)
}

// function setupRouletteTable () {
//   for (let index = 0; index < 36; index++) {
//     const button = new PIXI.Graphics()
//     button.lineStyle(0)
//     if (rouletteNumbers.indexOf(index + 1) % 2 == 0) {
//       button.beginFill(settings.color.odd, 1)
//     } else {
//       button.beginFill(settings.color.even, 1)
//     }

//     const xpos = 52 * ((index % 12) + 1)
//     const ypos = (Math.trunc(index / 3) * 52)
//     button.drawRoundedRect(xpos, ypos, 50, 50, 5)
//     button.endFill()
//     containers.rouletteTableContainer.addChild(button)

//     console.log
//     const tableText = new PIXI.Text(index + 1, settings.proximaNovaLight)
//     button.addChild(tableText)
//     tableText.anchor.set(0.5)
//     tableText.position.set(xpos + 25, ypos + 25)
//     tableText.scale.set(0.2)
//   }

//   for (let index = 0; index < 3; index++) {
//     const button = new PIXI.Graphics()
//     button.lineStyle(2, settings.color.even, 1)
//     button.beginFill(0xFFFFF, 0.0001)
//     const xpos = 53 + (settings.normalButtonSize.width + settings.padding) * 4 * index
//     const ypos = 160
//     button.drawRoundedRect(xpos, ypos, (settings.normalButtonSize.width * 4 + settings.padding * 2), settings.normalButtonSize.height, 5)
//     button.endFill()
//     containers.rouletteTableContainer.addChild(button)
//   }

//   for (let index = 0; index < 6; index++) {
//     const button = new PIXI.Graphics()
//     button.lineStyle(2, settings.color.even, 1)
//     button.beginFill(0xFFFFF, 0.0001)
//     const xpos = 53 + (settings.normalButtonSize.width + settings.padding) * 2 * index
//     const ypos = 215
//     button.drawRoundedRect(xpos, ypos, (settings.normalButtonSize.width * 2), settings.normalButtonSize.height, 5)
//     button.endFill()
//     containers.rouletteTableContainer.addChild(button)
//   }

//   const zeroButton = new PIXI.Graphics()
//   zeroButton.lineStyle(0)
//   zeroButton.beginFill(settings.color.neutral, 1)
//   const xpos = 0
//   const ypos = 0
//   zeroButton.drawRoundedRect(xpos, ypos, (settings.normalButtonSize.width),
//     settings.normalButtonSize.height * 3 + settings.padding * 2, 5)
//   zeroButton.endFill()
//   containers.rouletteTableContainer.addChild(zeroButton)

//   for (let index = 0; index < 3; index++) {
//     const button = new PIXI.Graphics()
//     button.lineStyle(2, settings.color.even, 1)
//     button.beginFill(0xFFFFF, 0.0001)
//     const xpos = 53 + (settings.normalButtonSize.width + settings.padding) * 12
//     const ypos = 1 + (Math.trunc(index / 1) * 52.5)
//     button.drawRoundedRect(xpos, ypos, (settings.normalButtonSize.width - settings.padding),
//       settings.normalButtonSize.height - settings.padding, 5)
//     button.endFill()
//     containers.rouletteTableContainer.addChild(button)
//   }

//   containers.rouletteTableContainer.scale.set(2.3 * currentRatio)
//   containers.rouletteTableContainer.position.set(-containers.rouletteTableContainer.width / 2, 0)
// }

function spriteSettings (spriteName, textureName, containerName) {
  spriteName.texture = textureName
  containerName.addChild(spriteName)
  spriteName.anchor.set(0.5)
  return spriteName
};

// function animatedSpriteSettings (spriteName, frames, containerName) {
//   spriteName = new PIXI.AnimatedSprite(frames)
//   spriteName.textures = frames
//   containerName.addChild(spriteName)
//   spriteName.anchor.set(0.5)
//   return spriteName
// };

export {
  containers,
  gameAssets,
  gameInitialValues,
  createUI,
  uiCreated,
  appAspectRatioX,
  appAspectRatioY
}
