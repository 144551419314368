import React from 'react'
import Footer from 'components/layout/Footer/index'
import NewHeader from 'components/layout/NewHeader/index'

const PublicRoute = ({ Component, isHeader, isFooter, child }) => {
  return (
    <>
      {/* {isHeader ? <NewHeader /> : <></>} */}
      <div className={isHeader ? '' : 'd-none'}>
        <NewHeader />
      </div>
      <Component child={child} />
      {isFooter ? <Footer /> : <></>}
    </>
  )
}

export default PublicRoute
