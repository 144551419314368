import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  demoPlaceBetPlinkoGameService,
  getMyBetsPlinkoService, placeBetPlinkoGameService,
  verifyProvablePlinkoService
} from 'pages/PlinkoGame/plinkoGame.service'
// import {
//   fastModeBox, pixiCancelBall, pixiCreateBall, pixiStartMovingBall
// } from 'pages/PlinkoGame/pixi-js-scripts/bridge'
import { updateCreditWalletWaitQueue } from 'redux-store/redux/slices/auth.slice'
import { setNewClientSeed, setServerSeedHash } from 'redux-store/redux/slices/gameSettings.slice'
import { appendMyBetsPlinko, setBetLock } from 'redux-store/redux/slices/plinkoGame.slice'
import { generateClientSeed } from 'utils/common-functions/index'
import { simulateBallCreation } from 'pages/PlinkoGame/pixi-js/bridge'
import { dataForBall } from 'pages/PlinkoGame/pixi-js/pixiHelper'

export const placedBetPlinkoGame = createAsyncThunk('plinko-game/place-bet',
  async ({ payload, fastMode }, thunkApi) => {
    try {
      const res = await placeBetPlinkoGameService(payload)
      if (res?.nextServerSeedHash) {
        const newClientSeed = generateClientSeed()
        thunkApi.dispatch(setNewClientSeed(newClientSeed))
        thunkApi.dispatch(setServerSeedHash(res.nextServerSeedHash))
      }
      const dropDetails = res.dropDetails[0]
      const winningSlots = res.winningSlots[0]
      if (fastMode) {
        for (let i = 0; i < dropDetails.length; i++) {
          // setTimeout(() => {
          //   fastModeBox(dropDetails[i])
          // }, 75 * i)
        }

        thunkApi.dispatch(setBetLock())
        thunkApi.dispatch(updateCreditWalletWaitQueue())
        thunkApi.dispatch(appendMyBetsPlinko())
      } else {
        for (let i = 0; i < dropDetails.length; i++) {
          // const lastBall = (i + 1) === dropDetails.length
          setTimeout(() => {
          }, 75 * i)
        }
        // dropDetails.forEach(detail => {
        //   pixiStartMovingBall(detail)
        //   pixiCreateBall()
        // })
      }
      res.dataForBalls = dataForBall(winningSlots)
      console.log(res.winningAmount / res.betAmount + 'x')
      simulateBallCreation(res.dataForBalls, winningSlots, dropDetails)
      return res
    } catch (error) {
      // if (!fastMode) {
      //   pixiCancelBall()
      // }
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const demoPlacedBetPlinkoGame = createAsyncThunk('plinko-game/demo-place-bet',
  async ({ payload, fastMode }, thunkApi) => {
    try {
      const res = await demoPlaceBetPlinkoGameService(payload)
      // const newDemoWallet = {
      //   currency: payload?.currencyCode,
      //   balance: res?.demoBalance
      // }
      // thunkApi.dispatch(appendCreditDemoWalletWaitQueue(newDemoWallet))
      if (fastMode) {
        const dropDetails = res.dropDetails
        for (let i = 0; i < dropDetails.length; i++) {
          setTimeout(() => {
            // fastModeBox(dropDetails[i])
          }, 75 * i)
        }
        thunkApi.dispatch(setBetLock())
        // thunkApi.dispatch(updateCreditDemoWalletWaitQueue())
        thunkApi.dispatch(appendMyBetsPlinko())
      } else {
        // const dropDetails = res.dropDetails
        // for (let i = 0; i < dropDetails.length; i++) {
        // const lastBall = (i + 1) === dropDetails.length
        // setTimeout(() => {
        //   pixiStartMovingBall(dropDetails[i])
        //   pixiCreateBall(lastBall)
        // }, 75 * i)
        // }
      }

      return res
    } catch (error) {
      // if (!fastMode) {
      //   pixiCancelBall()
      // }
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getMyBetsPlinko = createAsyncThunk('plinko-game/myBets', async (params, thunkApi) => {
  try {
    const res = await getMyBetsPlinkoService(params)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const verifyProvableFairPlinko = createAsyncThunk('plinko-game/check-provable-fair', async (payload, thunkApi) => {
  try {
    const res = await verifyProvablePlinkoService(payload)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})
