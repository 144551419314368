// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.provably-fair-modal .modal-dialog {
  max-width: 560px; }
  .provably-fair-modal .modal-dialog .provably-fair-title h5 {
    border-color: var(--purpleExDark) !important;
    font-family: 'Poppins', sans-serif; }
  .provably-fair-modal .modal-dialog .fairness-text {
    color: var(--white-4); }
`, "",{"version":3,"sources":["webpack://./src/pages/NewCrashGame/components/ProvablyFair/ProvablyFair.scss"],"names":[],"mappings":"AAAA;EAEI,gBAAgB,EAAA;EAFpB;IAKQ,4CAA4C;IAC5C,kCAAkC,EAAA;EAN1C;IAUM,qBAAqB,EAAA","sourcesContent":[".provably-fair-modal {\n  .modal-dialog {\n    max-width: 560px;\n    .provably-fair-title {\n      h5 {\n        border-color: var(--purpleExDark) !important;\n        font-family: 'Poppins', sans-serif;\n      }\n    }\n    .fairness-text {\n      color: var(--white-4);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
