// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.game-limit-modal .modal-dialog {
  max-width: 476px; }
  .game-limit-modal .modal-dialog .modal-body {
    padding-left: 1.625rem;
    padding-right: 1.625rem; }
    @media only screen and (max-width: 768px) {
      .game-limit-modal .modal-dialog .modal-body {
        padding-left: 1rem;
        padding-right: 1rem; } }
    .game-limit-modal .modal-dialog .modal-body .game-limit-title h5 {
      border-color: var(--purpleExDark) !important;
      font-family: 'Poppins', sans-serif; }
    .game-limit-modal .modal-dialog .modal-body .fairness-text {
      color: var(--white-4); }
    .game-limit-modal .modal-dialog .modal-body .top-bets-item {
      background-color: var(--bgSecondary);
      border-radius: 0.625rem;
      padding: 1rem; }
      .game-limit-modal .modal-dialog .modal-body .top-bets-item:not(:last-child) {
        margin-bottom: 0.625rem; }
      .game-limit-modal .modal-dialog .modal-body .top-bets-item .top-bets-result-box {
        background-color: var(--dropdownMenuBg);
        padding: 0.64rem 1rem;
        border-radius: 0.375rem; }
        .game-limit-modal .modal-dialog .modal-body .top-bets-item .top-bets-result-box .top-bets-result-label {
          color: var(--white-5); }
        .game-limit-modal .modal-dialog .modal-body .top-bets-item .top-bets-result-box:not(:last-child) {
          margin-bottom: 0.625rem; }
    @media only screen and (max-width: 768px) {
      .game-limit-modal .modal-dialog .modal-body .gold-coin-section {
        max-height: 330px;
        overflow: auto; } }
`, "",{"version":3,"sources":["webpack://./src/pages/NewCrashGame/components/GameLimit/GameLimit.scss"],"names":[],"mappings":"AAAA;EAEI,gBAAgB,EAAA;EAFpB;IAIM,sBAAsB;IACtB,uBAAuB,EAAA;IACvB;MANN;QAOQ,kBAAkB;QAClB,mBAAmB,EAAA,EA0CtB;IAlDL;MAYU,4CAA4C;MAC5C,kCAAkC,EAAA;IAb5C;MAkBQ,qBAAqB,EAAA;IAlB7B;MAsBQ,oCAAoC;MACpC,uBAAuB;MACvB,aAAa,EAAA;MAxBrB;QA2BU,uBAAuB,EAAA;MA3BjC;QA+BU,uCAAuC;QACvC,qBAAqB;QACrB,uBAAuB,EAAA;QAjCjC;UAoCY,qBAAqB,EAAA;QApCjC;UAwCY,uBAAuB,EAAA;IAK3B;MA7CR;QA8CU,iBAAiB;QACjB,cAAc,EAAA,EAEjB","sourcesContent":[".game-limit-modal {\n  .modal-dialog {\n    max-width: 476px;\n    .modal-body {\n      padding-left: 1.625rem;\n      padding-right: 1.625rem;\n      @media only screen and (max-width: 768px) {\n        padding-left: 1rem;\n        padding-right: 1rem;\n      }\n      .game-limit-title {\n        h5 {\n          border-color: var(--purpleExDark) !important;\n          font-family: 'Poppins', sans-serif;\n        }\n      }\n\n      .fairness-text {\n        color: var(--white-4);\n      }\n\n      .top-bets-item {\n        background-color: var(--bgSecondary);\n        border-radius: 0.625rem;\n        padding: 1rem;\n\n        &:not(:last-child) {\n          margin-bottom: 0.625rem;\n        }\n\n        .top-bets-result-box {\n          background-color: var(--dropdownMenuBg);\n          padding: 0.64rem 1rem;\n          border-radius: 0.375rem;\n\n          .top-bets-result-label {\n            color: var(--white-5);\n          }\n\n          &:not(:last-child) {\n            margin-bottom: 0.625rem;\n          }\n        }\n      }\n      .gold-coin-section {\n        @media only screen and (max-width: 768px) {\n          max-height: 330px;\n          overflow: auto;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
