import {
  app
} from './appPixi.js'
import {
  ballStoppedAtTarget
} from './gameManager.js'
import * as UI from './gameUI.js'
import {
  settings
} from './settings.js'

// const gameValues = {
//   ...UI.gameInitialValues
// }

// const appHeight = app.screen.height
// const appWidth = app.screen.width

const keys = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
  19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36
]
const values = [100, 323, 158, 80, 140, 285, 197, 41, 255, 3, 275, 235, 60, 217, 344, 120, 305, 178, 23,
  130, 333, 147, 13, 265, 295, 167, 90, 206, 50, 33, 245, 351, 110, 313, 188, 70, 227
]
const obj = {}

for (let i = 0; i < keys.length; i++) {
  obj[keys[i]] = values[i]
}

let ballSpeed = settings.rouletteBallSpeed
let rouletteSpeed = settings.rouletteWheelSpeed
let boolToStop = false
let boolStopped = false
let boolBounce = false

let netCal = 1

let targetNumber = 0

let bounceHeight = 5
let MAXbounceHeight = 5

let boolInstantBet = false
let boolAnimation = false

function startGame () {
  ballSpeed = boolInstantBet ? 0 : 8
  UI.gameAssets.rouletteWheel.Ball.visible = !boolInstantBet
}

function stopGame (n) {
  boolToStop = true
  targetNumber = n

  if (boolInstantBet) {
    UI.containers.rouletteBallContainer.angle = Math.round((obj[targetNumber]) % 360)
    UI.gameAssets.rouletteWheel.Ball.x = 72
    UI.gameAssets.rouletteWheel.Ball.visible = true

    BallStopped()
  }
}

function setValuesToInitial () {
  boolToStop = false
  boolStopped = false
  ballSpeed = (boolInstantBet || !boolAnimation) ? 0 : 0.8
  boolBounce = false
  UI.gameAssets.rouletteWheel.Ball.x = 150
  bounceHeight = 5
  MAXbounceHeight = 5
  UI.gameAssets.rouletteWheel.Ball.visible = false
}

function setAnimation (bool) {
  console.log('setAnimation= ', bool)
  if (!bool) {
    rouletteSpeed = 0
    ballSpeed = 0
    boolAnimation = false
  } else {
    rouletteSpeed = settings.rouletteWheelSpeed
    ballSpeed = settings.rouletteWheelSpeed
    boolAnimation = true
  }
}

function setInstantBet (bool) {
  if (bool) {
    boolInstantBet = true
  } else {
    boolInstantBet = false
  }
}

function BallStopped () {
  ballStoppedAtTarget()
}

app.ticker.add((delta) => {
  // if (dddx > 0) {
  //     UI.gameAssets.ball.y = appHeight / 2

  //     if (UI.gameAssets.ball.x <= 10) {
  //         dddx -= dddx * 0.1
  //         ddx = dddx
  //     }

  //     ddx -= .5;
  //     UI.gameAssets.ball.x += ddx
  // }
  //= ======================================================Method 1 ==============================================================
  // if (boolToStop) {
  //     if (Math.round(UI.containers.rouletteBallContainer.angle % 360) >= obj[targetNumber] - 1 &&
  //         Math.round(UI.containers.rouletteBallContainer.angle % 360) <= obj[targetNumber] + 3) {
  //         ballSpeed = 0;
  //     }
  // }

  //  console.log(Math.round(UI.containers.rouletteBallContainer.angle % 360));

  // if (boolToStop) {

  //     if (!boolStopped && ballSpeed > 50 && UI.gameAssets.ball.x > 125) {
  //         ballSpeed -= ballSpeed * 0.05;
  //         UI.gameAssets.ball.x -= 0.4;
  //     } else if (!boolStopped && ((obj[targetNumber] + 175) % 360) < Math.round(UI.containers.rouletteBallContainer.angle % 360) &&
  //         ((obj[targetNumber] + 185) % 360) > Math.round(UI.containers.rouletteBallContainer.angle % 360)) {
  //         if (ballSpeed < 40 && ballSpeed > 30) {
  //             ballSpeed -= ballSpeed * 0.15;
  //             UI.gameAssets.ball.x -= UI.gameAssets.ball.x * 0.8;
  //         }
  //     } else if (!boolStopped && ((obj[targetNumber] + 265) % 360) < Math.round(UI.containers.rouletteBallContainer.angle % 360) &&
  //         ((obj[targetNumber] + 270) % 360) > Math.round(UI.containers.rouletteBallContainer.angle % 360)) {
  //         if (ballSpeed < 30 && ballSpeed > 10) {
  //             ballSpeed -= ballSpeed * 0.2;
  //             UI.gameAssets.ball.x -= UI.gameAssets.ball.x * 0.8;
  //         }
  //         console.log("Else If 2");
  //     } else if (!boolStopped && ((obj[targetNumber] - 5) % 360) < Math.round(UI.containers.rouletteBallContainer.angle % 360) &&
  //         ((obj[targetNumber]) % 360) > Math.round(UI.containers.rouletteBallContainer.angle % 360)) {
  //         console.log("Else If 3");
  //         if (ballSpeed > 0 && ballSpeed < 10) {
  //             ballSpeed -= ballSpeed * 0.7;
  //         } else {
  //             ballSpeed = 0;
  //             boolStopped = true;
  //         }

  //         if( UI.gameAssets.ball.x >50){
  //            // UI.gameAssets.ball.x -= UI.gameAssets.ball.x * 0.1;
  //         }
  //     }
  // }

  //= ========================Method 2==========================================================================
  if (boolToStop && !boolInstantBet) {
    if (!boolStopped && (Math.round((obj[targetNumber] - 10)) < Math.round(UI.containers.rouletteBallContainer.angle % 360)) &&
      (((obj[targetNumber])) > Math.round(UI.containers.rouletteBallContainer.angle % 360))) {
      boolStopped = true
    }

    if (boolStopped) {
      if (UI.gameAssets.rouletteWheel.Ball.x > 105 && !boolBounce) {
        UI.gameAssets.rouletteWheel.Ball.x -= UI.gameAssets.rouletteWheel.Ball.x * 0.1
      } else {
        boolBounce = true
      }
      if (netCal > 0) {
        netCal = 1 - Math.abs(((Math.round(UI.containers.rouletteBallContainer.angle) - obj[targetNumber]) % (360))) / (360)
      }

      if (ballSpeed > 4) {
        ballSpeed -= (ballSpeed * (netCal) * 0.02)
      } else if (ballSpeed < 4) {
        if (Math.round((UI.containers.rouletteBallContainer.angle) % 360) > Math.abs(Math.round((obj[targetNumber] - 35))) &&
          Math.round((UI.containers.rouletteBallContainer.angle) % 360) < Math.round((obj[targetNumber] - 5))) {
          if (ballSpeed > 0.5) {
            ballSpeed -= ballSpeed * 0.09
            // if (UI.gameAssets.rouletteWheel.Ball.x > 125) {
            //     UI.gameAssets.rouletteWheel.Ball.x -= UI.gameAssets.rouletteWheel.Ball.x * 0.02
            // }
          }
        } else if (Math.round((UI.containers.rouletteBallContainer.angle) % 360) > Math.abs(Math.round((obj[targetNumber] - 3))) &&
          Math.round((UI.containers.rouletteBallContainer.angle) % 360) < Math.round((obj[targetNumber] + 2))) {
          ballSpeed -= ballSpeed * 0.15
          if (ballSpeed > 0.3) {
            // if (UI.gameAssets.rouletteWheel.Ball.x >= 117) {
            //     UI.gameAssets.rouletteWheel.Ball.x -= UI.gameAssets.rouletteWheel.Ball.x * 0.05
            // }
            // } else {
            //  ballSpeed = 0
            // if (UI.gameAssets.rouletteWheel.Ball.x >= 112)
            //     UI.gameAssets.rouletteWheel.Ball.x = 112
            if (UI.containers.rouletteBallContainer.angle >= Math.round((obj[targetNumber]) % 360)) {
              UI.containers.rouletteBallContainer.angle = Math.round((obj[targetNumber]) % 360)
              ballSpeed = 0
              BallStopped()
            }
          }
        }
      }

      if (MAXbounceHeight > 0.5) {
        if (boolBounce) {
          if (UI.gameAssets.rouletteWheel.Ball.position.x <= 72) {
            MAXbounceHeight -= MAXbounceHeight * 0.1
            bounceHeight = MAXbounceHeight
          }
          bounceHeight -= 0.3
          UI.gameAssets.rouletteWheel.Ball.position.x += bounceHeight
        }
      }
    }
    //  }
  }

  UI.containers.rouletteContainer.angle -= rouletteSpeed * delta
  UI.containers.rouletteBallContainer.angle += ballSpeed * delta
})

export {
  startGame,
  setValuesToInitial,
  stopGame,
  setAnimation,
  setInstantBet
}
