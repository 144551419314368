import * as PIXI from 'pixi.js'
import * as UI from './gameUI.js'
import { pixiApp } from './initializePixi.js'
import Matter from 'matter-js'

// fps calculation
let startTime = performance.now()
const lastTime = 0
let frameCount = 0
let totalFrameCount = 0
let totalTimeElapsed = 0
let averageFPS = 0

let pixiTickerRun = false
let MaxFps = 0
const setTickerRun = (value) => pixiTickerRun = value

const startGameTicker = () => {
  MaxFps = pixiApp.app.ticker.FPS
  console.log('start Ticker')
  pixiApp.app.ticker.add((delta) => {
    // Calculate FPS
    frameCount++
    totalFrameCount++

    // Calculate elapsed time since the start
    const currentTime = performance.now()
    const elapsed = currentTime - startTime

    // Update average FPS every second
    if (elapsed >= 1000) {
      // Update average FPS
      totalTimeElapsed += elapsed
      averageFPS = Math.round((totalFrameCount * 1000) / totalTimeElapsed)

      // Reset frame count and time for the next interval
      frameCount = 0
      totalFrameCount = 0
      totalTimeElapsed = 0
      startTime = currentTime
    }
    // console.log("avg FPS: " + averageFPS);
    Matter.Engine.update(UI.matterAssets.engine)
    //  console.log(pixiApp.app.ticker.FPS)
    Matter.Engine.update(UI.matterAssets.engine)
    // Matter.Engine.update(UI.matterAssets.engine, delta)
    UI.assets.boxes.forEach(box => box.boxContainer.boxState(delta))

    //  console.log("delta: " + delta)
    //  console.log("delta: " + delta)
    if (!pixiTickerRun) return

    // assets.points[0].pixiBody.position.set(assets.points[0].matterBody.position.x, assets.points[0].matterBody.position.y);

    UI.assets.activeBalls.forEach(ball => {
      ball.pixiBody.position.set(ball.matterBody.position.x, ball.matterBody.position.y)
      ball.pixiBody.position.set(ball.matterBody.position.x, ball.matterBody.position.y)
    })
  })

  pixiApp.app.ticker.start()
}

const stopGameTicker = () => pixiApp.app.ticker.stop()

export {
  setTickerRun,
  startGameTicker,
  stopGameTicker
}

export function getFPS () {
  console.log('fps ', averageFPS)
  return 60 / averageFPS
}
