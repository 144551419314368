// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `header {
  position: relative;
  z-index: 1002; }
  @media screen and (max-width: 767px) {
    header {
      position: sticky;
      top: 0; } }
  header nav {
    padding: 1.125rem 0 !important; }
    @media screen and (max-width: 767px) {
      header nav {
        padding: 0.25rem 0 !important; }
        header nav .navbar-brand {
          max-width: 77px; } }
    @media screen and (max-width: 767px) {
      header nav > .custom-container {
        padding-left: 0.25rem;
        padding-right: 0.25rem; } }
    header nav > .custom-container .header-flex-box {
      min-height: 93px;
      border-radius: 0 0 1.5rem 1.5rem;
      background-color: var(--bgSecondary); }
      @media screen and (max-width: 767px) {
        header nav > .custom-container .header-flex-box {
          min-height: auto;
          border-radius: 0 0 .5rem .5rem; } }
      header nav > .custom-container .header-flex-box .nav-balance .nav-balance-label {
        color: var(--brown-800); }
`, "",{"version":3,"sources":["webpack://./src/components/layout/NewHeader/Header.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa,EAAA;EAEb;IAJF;MAKI,gBAAgB;MAChB,MAAM,EAAA,EAoCT;EA1CD;IAUI,8BAA8B,EAAA;IAE9B;MAZJ;QAaM,6BAA6B,EAAA;QAbnC;UAeQ,eAAe,EAAA,EAChB;IAID;MApBN;QAqBQ,qBAAqB;QACrB,sBAAsB,EAAA,EAkBzB;IAxCL;MAyBQ,gBAAgB;MAChB,gCAAgC;MAChC,oCAAoC,EAAA;MAEpC;QA7BR;UA8BU,gBAAgB;UAChB,8BAA8B,EAAA,EAQjC;MAvCP;QAoCY,uBAAuB,EAAA","sourcesContent":["header {\n  position: relative;\n  z-index: 1002;\n\n  @media screen and (max-width: 767px) {\n    position: sticky;\n    top: 0;\n  }\n\n  nav {\n    padding: 1.125rem 0 !important;\n\n    @media screen and (max-width: 767px) {\n      padding: 0.25rem 0 !important;\n      .navbar-brand {\n        max-width: 77px;\n      }\n    }\n\n    &>.custom-container {\n      @media screen and (max-width: 767px) {\n        padding-left: 0.25rem;\n        padding-right: 0.25rem;\n      }\n      .header-flex-box {\n        min-height: 93px;\n        border-radius: 0 0 1.5rem 1.5rem;\n        background-color: var(--bgSecondary);\n\n        @media screen and (max-width: 767px) {\n          min-height: auto;\n          border-radius: 0 0 .5rem .5rem;\n        }\n\n        .nav-balance {\n          .nav-balance-label {\n            color: var(--brown-800);\n          }\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
