import { lazy } from 'react'
import { ROUTE_PATHS } from 'utils/constants/index'

const NewCrashGame = lazy(() => import('pages/NewCrashGame/index'))
const MineGame = lazy(() => import('pages/MineGame/index'))
const PlinkoGame = lazy(() => import('pages/PlinkoGame/index'))
const BlackjackGame = lazy(() => import('pages/BlackjackGame/index'))
const RouletteGame = lazy(() => import('pages/RouletteGame/index'))

const routesList = [
  {
    path: ROUTE_PATHS.crashGame,
    component: NewCrashGame,
    isPrivate: false,
    isHeader: true,
    isFooter: false
  },
  {
    path: ROUTE_PATHS.mineGame,
    component: MineGame,
    isPrivate: false,
    isHeader: false,
    isFooter: false
  },
  {
    path: ROUTE_PATHS.plinkoGame,
    component: PlinkoGame,
    isPrivate: false,
    isHeader: false,
    isFooter: false
  },
  {
    path: ROUTE_PATHS.blackjackGame,
    component: BlackjackGame,
    isPrivate: false,
    isHeader: false,
    isFooter: false
  },
  {
    path: ROUTE_PATHS.rouletteGame,
    component: RouletteGame,
    isPrivate: false,
    isHeader: false,
    isFooter: false
  },
  {
    path: ROUTE_PATHS.rouletteGame,
    component: RouletteGame,
    isPrivate: false,
    isHeader: false,
    isFooter: false
  }
]

export default routesList
