import { resetGame, start, stop } from './gameManager'

export const pixiStartRoulette = () => {
  start()
}

export const pixiStopRoulette = (stopNumber) => {
  stop(stopNumber)
}

export const pixiResetRoulette = () => {
  resetGame()
}
