// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.range-slider-input {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  height: 18px;
  border-radius: 2.25rem;
  background: var(--brown-900);
  border: 2px solid var(--brown-975);
  outline: none;
  padding: 0;
  margin: 0;
  cursor: pointer; }
  .range-slider-input::-webkit-slider-thumb {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: var(--green-900);
    border: 3px solid var(--white);
    cursor: pointer;
    -webkit-transition: background .15s ease-in-out;
    transition: background .15s ease-in-out; }
    .range-slider-input::-webkit-slider-thumb:hover {
      transform: scale(0.97); }
    .range-slider-input::-webkit-slider-thumb:active {
      transform: scale(0.92); }
`, "",{"version":3,"sources":["webpack://./src/components/ui-kit/RangeSlider/rangeSlider.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,wBAAwB;EACxB,qBAAqB;EACrB,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,4BAA4B;EAC5B,kCAAkC;EAClC,aAAa;EACb,UAAU;EACV,SAAS;EACT,eAAe,EAAA;EAZjB;IAeI,gBAAgB;IAChB,wBAAwB;IACxB,qBAAqB;IACrB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,4BAA4B;IAC5B,8BAA8B;IAC9B,eAAe;IACf,+CAAuC;IAAvC,uCAAuC,EAAA;IAxB3C;MA0BM,sBAAsB,EAAA;IA1B5B;MA6BM,sBAAsB,EAAA","sourcesContent":[".range-slider-input {\n  appearance: none;\n  -webkit-appearance: none;\n  -moz-appearance: none;\n  width: 100%;\n  height: 18px;\n  border-radius: 2.25rem;\n  background: var(--brown-900);\n  border: 2px solid var(--brown-975);\n  outline: none;\n  padding: 0;\n  margin: 0;\n  cursor: pointer;\n  // Range Handle\n  &::-webkit-slider-thumb {\n    appearance: none;\n    -webkit-appearance: none;\n    -moz-appearance: none;\n    width: 20px;\n    height: 20px;\n    border-radius: 50%;\n    background: var(--green-900);\n    border: 3px solid var(--white);\n    cursor: pointer;\n    transition: background .15s ease-in-out;\n    &:hover{\n      transform: scale(0.97);\n    }\n    &:active {\n      transform: scale(0.92);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
