import { createSlice } from '@reduxjs/toolkit'
import { plus, minus } from 'number-precision'
import { placedBetPlinkoGame, demoPlacedBetPlinkoGame, getMyBetsPlinko } from 'redux-store/thunk/plinkoGame.thunk'
import { DEFAULT_PAGE_CALLS, DEFAULT_PLINKO_LIGHTNING_MODE_BOARD } from 'pages/PlinkoGame/plinkogame.constants'
import { BET_RESULT } from 'utils/constants/index'

const initialState = {
  placedBetData: null,
  placedBets: [],
  placedBetForOneRound: [],
  betLock: false,
  betLoading: false,
  myBetsData: {
    count: 0,
    rows: []
  },
  wins: 0,
  losses: 0,
  profit: 0,
  wagered: 0,
  myBetsLoading: false,
  lightningBoardLoading: false,
  lightningBoardBallMultipliers: DEFAULT_PLINKO_LIGHTNING_MODE_BOARD.betMultipliers,
  lightningBoardPayouts: DEFAULT_PLINKO_LIGHTNING_MODE_BOARD.payouts,
  plinkoVolume: 1
}

const {
  actions: {
    setBetLock,
    setLightningBoardDetails,
    appendMyBetsPlinko,
    setPlinkoLossCount,
    setPlinkoWinsCount,
    setPlinkoProfit,
    setPlinkoWagered,
    setPlinkoVolume
  },
  reducer
} = createSlice({
  name: 'plinkoGame',
  initialState,
  reducers: {
    setPlinkoWinsCount: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          wins: action.payload.reset
        }
      }
      return {
        ...state,
        wins: state.wins + 1
      }
    },
    setPlinkoLossCount: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          losses: action.payload.reset
        }
      }
      return {
        ...state,
        losses: state.losses + 1
      }
    },
    setPlinkoProfit: (state, action) => {
      return {
        ...state,
        profit: action.payload
      }
    },
    setPlinkoWagered: (state, action) => {
      return {
        ...state,
        wagered: action.payload
      }
    },
    setBetLock: (state, action) => {
      return {
        ...state,
        betLock: !(state?.placedBetForOneRound?.length === 0)
      }
    },
    setLightningBoardDetails: (state, action) => {
      return {
        ...state,
        lightningBoardBallMultipliers: action.payload?.lightningBoardBallMultipliers ?? DEFAULT_PLINKO_LIGHTNING_MODE_BOARD.betMultipliers,
        lightningBoardPayouts: action.payload?.lightningBoardPayouts ?? DEFAULT_PLINKO_LIGHTNING_MODE_BOARD.payouts
      }
    },
    setPlinkoVolume: (state, action) => ({ ...state, plinkoVolume: action.payload }),
    appendMyBetsPlinko: (state, action) => {
      let _placedBets = [...(state?.placedBets ?? [])]
      const _betToAppend = _placedBets?.[_placedBets.length - 1]
      _placedBets = _placedBets?.slice(0, _placedBets.length - 1)
      const { nextServerSeedHash, ...data } = _betToAppend || { nextServerSeedHash: '', data: {} }
      if (nextServerSeedHash) {
        const myBetsDataRows = [data, ...(state?.myBetsData?.rows ?? [])]
        if (myBetsDataRows.length > DEFAULT_PAGE_CALLS) {
          myBetsDataRows.pop()
        }
        const profit = plus(state.profit, minus(_betToAppend?.winningAmount ?? 0, _betToAppend?.betAmount ?? 0))
        const wagered = plus(state.wagered, _betToAppend?.betAmount)
        const wins = plus(state.wins, _betToAppend?.result === BET_RESULT.WON ? 1 : 0)
        const losses = plus(state.losses, _betToAppend?.result === BET_RESULT.LOST ? 1 : 0)
        return {
          ...state,
          placedBets: _placedBets,
          placedBetForOneRound: _placedBets,
          betLock: false,
          profit,
          wagered,
          wins,
          losses,
          myBetsData: {
            count: state.myBetsData?.count + 1,
            rows: myBetsDataRows
          }
        }
      }
      return state

      // const _placedBets = [...(state?.placedBetForOneRound ?? [])]
      // let newState = state
      // let myBetsDataRows = [...(state?.myBetsData?.rows ?? [])]
      // let profit = state.profit
      // let wagered = state.wagered
      // let wins = state.wins
      // let losses = state.losses
      // _placedBets.forEach((_betToAppend, idx) => {
      //   const { nextServerSeedHash, ...data } = _betToAppend || { nextServerSeedHash: '', data: {} }
      //   if (nextServerSeedHash) {
      //     myBetsDataRows = [data, ...(myBetsDataRows ?? [])]
      //     if (myBetsDataRows.length > DEFAULT_PAGE_CALLS) {
      //       myBetsDataRows.pop()
      //     }
      //     profit = plus(profit, minus(_betToAppend?.winningAmount ?? 0, _betToAppend?.betAmount ?? 0))
      //     wagered = plus(wagered, _betToAppend?.betAmount)
      //     wins = plus(wins, _betToAppend?.result === BET_RESULT.WON ? 1 : 0)
      //     losses = plus(losses, _betToAppend?.result === BET_RESULT.LOST ? 1 : 0)
      //     if (_placedBets?.length === idx + 1) {
      //       newState = {
      //         ...state,
      //         placedBetForOneRound: [],
      //         betLock: false,

      //         // ...(_placedBets?.length === 0
      //         //   ? {
      //         //       betLock: false
      //         //     }
      //         //   : {}),
      //         profit,
      //         wagered,
      //         wins,
      //         losses,
      //         myBetsData: {
      //           count: state.myBetsData?.count + 1,
      //           rows: myBetsDataRows
      //         }
      //       }
      //     }
      //   }
      // })
      // return newState
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(placedBetPlinkoGame.pending, (state, action) => {
        return {
          ...state,
          betLock: true,
          betLoading: true
        }
      })
      .addCase(placedBetPlinkoGame.fulfilled, (state, action) => {
        return {
          ...state,
          placedBets: [action.payload, ...(state?.placedBets ?? [])],
          placedBetForOneRound: [action.payload, ...(state?.placedBetForOneRound ?? [])],
          placedBetData: action.payload,
          betLoading: false
        }
      })
      .addCase(placedBetPlinkoGame.rejected, (state, action) => {
        return {
          ...state,
          betLock: false,
          betLoading: false
        }
      })
      .addCase(demoPlacedBetPlinkoGame.pending, (state, action) => {
        return {
          ...state,
          betLock: true,
          betLoading: true
        }
      })
      .addCase(demoPlacedBetPlinkoGame.fulfilled, (state, action) => {
        return {
          ...state,
          placedBets: [action.payload, ...(state?.placedBets ?? [])],
          placedBetData: action.payload,
          betLoading: false
        }
      })
      .addCase(demoPlacedBetPlinkoGame.rejected, (state, action) => {
        return {
          ...state,
          betLock: false,
          betLoading: false
        }
      })
      .addCase(getMyBetsPlinko.pending, (state, action) => {
        return {
          ...state,
          myBetsData: action.payload,
          myBetsLoading: true

        }
      })
      .addCase(getMyBetsPlinko.fulfilled, (state, action) => {
        return {
          ...state,
          myBetsData: action.payload,
          myBetsLoading: false

        }
      })
  }
})

export {
  setBetLock,
  setLightningBoardDetails,
  appendMyBetsPlinko,
  setPlinkoLossCount,
  setPlinkoWinsCount,
  setPlinkoProfit,
  setPlinkoWagered,
  setPlinkoVolume
}

export default reducer
