import * as PIXI from "pixi.js"
import { gameManager } from "./gameManager.js";
import { path } from "./settings.js";


export let assetsLoaded = false
export let promiseAssets

// startLoadingAssets()

export function startLoadingAssets(setIsPixiLoading) {

    assetsLoaded = false

    loadAssets()
        .then(function () {
            onAssetsLoaded()
            setIsPixiLoading(false)
            console.log("Assets loaded");
        })
        .catch(function (e) {
            console.error(e)
        })
}

async function loadAssets() {

    PIXI.Assets.addBundle('GameAssets', {
      bold: path.fontPath+'Barlow-Bold.ttf',
      semiBold: path.fontPath+'Barlow-SemiBold.ttf',
      ultraRegular: path.fontPath+'Ultra-Regular.ttf',
      allAssets: path.spriteSheetsPath+'mainAssets.json',
      explosion: path.spriteSheetsPath+'explosion.json',
      Bg: path.imagePath + 'Bg.png',
      clouds: path.spriteSheetsPath+'clouds.json',
      loaderFiller: path.imagePath+'loaderFiller.png',
      loaderBg: path.imagePath+'loaderBg.png'
    });

   promiseAssets = await PIXI.Assets.loadBundle('GameAssets');
}

function onAssetsLoaded() {
    assetsLoaded = true
    gameManager()
}
