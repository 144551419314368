import { createSlice } from '@reduxjs/toolkit'
import {
  betListBlackjackGame, getBlackJackHighRollerTransactions, getBlackJackTransactions,
  getMyBlackJackTransactions, unfinishedBetBlackjackGame
} from 'pages/BlackjackGame/slice-thunk/blackjack.thunk'

const initialState = {
  loading: false,
  betsLoading: false,
  betList: null,
  unFinishedBet: null,
  blackjackTransactions: null,
  myBlackjackTransactions: null,
  blackjackHighRollerTransactions: null
}

const {
  actions: {
    resetUnfinishedBet,
    setUpdateMyBet
  },
  reducer
} = createSlice({
  name: 'blackjack',
  initialState,
  reducers: {
    resetUnfinishedBet: (state, action) => ({
      ...state,
      unFinishedBet: null
    }),
    setUpdateMyBet: (state, action) => {
      return ({
        ...state,
        myBlackjackTransactions: action.payload
      })
    }
  },
  extraReducers: builder => {
    builder
      .addCase(betListBlackjackGame.fulfilled, (state, { payload }) => ({
        ...state,
        betList: payload
      }))
      .addCase(unfinishedBetBlackjackGame.fulfilled, (state, { payload }) => ({
        ...state,
        unFinishedBet: payload
      }))
      .addCase(getBlackJackTransactions.pending, (state, action) => {
        return {
          ...state,
          betsLoading: true
        }
      })
      .addCase(getBlackJackTransactions.fulfilled, (state, action) => {
        return {
          ...state,
          blackjackTransactions: action?.payload,
          betsLoading: false
        }
      })
      .addCase(getBlackJackTransactions.rejected, (state, action) => {
        return {
          ...state,
          betsLoading: false
        }
      })
      .addCase(getMyBlackJackTransactions.pending, (state, action) => {
        return {
          ...state,
          betsLoading: true
        }
      })
      .addCase(getMyBlackJackTransactions.fulfilled, (state, action) => {
        return {
          ...state,
          myBlackjackTransactions: action?.payload,
          betsLoading: false
        }
      })
      .addCase(getMyBlackJackTransactions.rejected, (state, action) => {
        return {
          ...state,
          betsLoading: false
        }
      })
      .addCase(getBlackJackHighRollerTransactions.pending, (state, action) => {
        return {
          ...state,
          betsLoading: true
        }
      })
      .addCase(getBlackJackHighRollerTransactions.fulfilled, (state, action) => {
        return {
          ...state,
          blackjackHighRollerTransactions: action?.payload,
          betsLoading: false
        }
      })
      .addCase(getBlackJackHighRollerTransactions.rejected, (state, action) => {
        return {
          ...state,
          betsLoading: false
        }
      })
  }
})

export { resetUnfinishedBet, setUpdateMyBet }

export default reducer
