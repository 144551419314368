import { combineReducers } from '@reduxjs/toolkit'
import gameSetting from './slices/gameSettings.slice'
import crashGame from './slices/crashGame.slice'
import mineGame from './slices/mineGame.slice'
import plinkoGame from './slices/plinkoGame.slice'
import authSlice from './slices/auth.slice'
import blackjackGame from 'pages/BlackjackGame/slice-thunk/blackjack.slice'
import rouletteGame from 'pages/RouletteGame/rouletteGame.slice'

const rootReducer = combineReducers({
  auth: authSlice,
  crashGame,
  gameSetting,
  mineGame,
  plinkoGame,
  blackjackGame,
  rouletteGame
})

export default rootReducer
