import React, { useEffect, useState } from 'react'
import './GameLimit.scss'
import Popup from 'components/ui-kit/Popup/index'
import Button from 'components/ui-kit/Button/index'
import { CrossIcon } from 'assets/icons/index'
import { COIN_CURRENCY_CODES, DEFAULT_GAME_ID } from 'utils/constants/index'
import { useSelector } from 'react-redux'
import { numberWithCommas } from 'utils/common-functions/index'

const GameLimit = ({
  setIsOpenGameLimits,
  isOpenGameLimits

}) => {
  const [currentGameSetting, setCurrentGameSetting] = useState(null)
  const { systemGameData } = useSelector((state) => state.gameSetting)

  useEffect(() => {
    const setting = systemGameData?.find(ele => ele.gameId === DEFAULT_GAME_ID.CRASH)
    setCurrentGameSetting(setting)
  }, [systemGameData])

  return (
    <Popup className='game-limit-modal' isOpenModal={isOpenGameLimits}>
      <Button className='btn-outline-primary btn-close-modal p-0' onClick={() => setIsOpenGameLimits(false)}>
        <div>
          <CrossIcon />
        </div>
      </Button>
      <div className='text-center text-white py-1'>
        <div className='game-limit-title'>
          <h5 className='mb-3 pb-4 border-bottom'>Game Limits</h5>
        </div>
        <div>
          {/* <p className='fw-normal mb-4'>
          </p> */}
          <div className='gold-coin-section'>
            {/* Main items */}
            <div className='top-bets-item text-start'>
              <div className='mb-2 d-flex align-items-center'>
                <img src='/assets/images/common/switch-yellow-coin.png' alt='coin' className='w-24 float-start float-start me-2' />
                <h6 className='mb-0'>Gold Coins</h6>
              </div>
              {/* items */}
              <div className='top-bets-result-box d-flex justify-content-between'>
                <span className='top-bets-result-label'>Minimum Bet:</span>
                <span>
                  <img src='/assets/images/common/switch-yellow-coin.png' alt='coin' className='w-18 float-start float-start me-1' />
                  {`${numberWithCommas(currentGameSetting?.minBet[COIN_CURRENCY_CODES.USD])}`} {COIN_CURRENCY_CODES.USD}
                </span>
              </div>
              {/* items */}
              <div className='top-bets-result-box d-flex justify-content-between'>
                <span className='top-bets-result-label'>Maximum Bet:</span>
                <span>
                  <img src='/assets/images/common/switch-yellow-coin.png' alt='coin' className='w-18 float-start float-start me-1' />
                  {`${numberWithCommas(currentGameSetting?.maxBet[COIN_CURRENCY_CODES.USD])}`} {COIN_CURRENCY_CODES.USD}
                </span>
              </div>
              {/* items */}
              <div className='top-bets-result-box d-flex justify-content-between'>
                <span className='top-bets-result-label'>Maximum win for one bet:</span>
                <span className='text-golden'>
                  <img src='/assets/images/common/switch-yellow-coin.png' alt='coin' className='w-18 float-start float-start me-1' />
                  {`${numberWithCommas(currentGameSetting?.maxProfit[COIN_CURRENCY_CODES.USD])}`} {COIN_CURRENCY_CODES.USD}
                </span>
              </div>
            </div>
            {/* Main items */}
            <div className='top-bets-item text-start'>
              <div className='mb-2 d-flex align-items-center'>
                <img src='/assets/images/common/switch-green-coin.png' alt='coin' className='w-24 float-start float-start me-2' />
                <h6 className='mb-0'>Sweepstakes Coins</h6>
              </div>
              {/* items */}
              <div className='top-bets-result-box d-flex justify-content-between'>
                <span className='top-bets-result-label'>Minimum Bet:</span>
                <span>
                  <img src='/assets/images/common/switch-green-coin.png' alt='coin' className='w-18 float-start float-start me-1' />
                  {`${numberWithCommas(currentGameSetting?.minBet[COIN_CURRENCY_CODES.EUR])}`} {COIN_CURRENCY_CODES.EUR}
                </span>
              </div>
              {/* items */}
              <div className='top-bets-result-box d-flex justify-content-between'>
                <span className='top-bets-result-label'>Maximum Bet:</span>
                <span>
                  <img src='/assets/images/common/switch-green-coin.png' alt='coin' className='w-18 float-start float-start me-1' />
                  {`${numberWithCommas(currentGameSetting?.maxBet[COIN_CURRENCY_CODES.EUR])}`} {COIN_CURRENCY_CODES.EUR}
                </span>
              </div>
              {/* items */}
              <div className='top-bets-result-box d-flex justify-content-between'>
                <span className='top-bets-result-label'>Maximum win for one bet:</span>
                <span className='text-green'>
                  <img src='/assets/images/common/switch-green-coin.png' alt='coin' className='w-18 float-start float-start me-1' />
                  {`${numberWithCommas(currentGameSetting?.maxProfit[COIN_CURRENCY_CODES.EUR])}`} {COIN_CURRENCY_CODES.EUR}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default GameLimit
