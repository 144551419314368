import {
  startLoadingAssets
} from "./assetsLoad.js";
import {
  createUI, toggleAnimation
} from "./gameUI.js";
import {
  startGame,
  stopGame,
  setValuesToInitial,
  startLoading,
  changeRocket,
  setCenterText,
  setCashoutText
} from "./gameLogic.js";

//startLoadingAssets()

export function gameManager() {
  createUI()
}


//For React Team========================
export function showLoadingScreen() {
  startLoading(5)
}

export function displayPayout(payout) {
  // console.log(payout, 'payout')
  setCenterText(payout + 'X')
}

//For React Team========================
export function loadingStopped() {
  // console.log("loading Stopped")
}

//For React Team========================
export function loadGame() {
  //startLoading(5)
}

//For React Team========================
export function resetGame() {
  setValuesToInitial()
}

//For React Team========================
export function start() {
  startGame()
}

export function stop() {
  stopGame()
}

export function setCashoutAmount(num1, num2) {
  // setCashoutText(num1, num2)
}

export function changeRocketImg() {
  // changeRocket(1)
}

export function animationVisibility(toggle = true) {
  toggleAnimation(toggle)
}

// const showLoadDiv = document.createElement('button')
// showLoadDiv.innerHTML = 'show Load'
// showLoadDiv.onclick = showLoadingScreen
// document.body.appendChild(showLoadDiv);

// const loadDiv = document.createElement('button')
// loadDiv.innerHTML = 'start Load'
// loadDiv.onclick = loadGame
// document.body.appendChild(loadDiv);

// const startDiv = document.createElement('button')
// startDiv.innerHTML = 'Start'
// startDiv.onclick = start
// document.body.appendChild(startDiv);

// const stopDiv = document.createElement('button')
// stopDiv.innerHTML = 'Stop'
// stopDiv.onclick = stop
// document.body.appendChild(stopDiv);

// const resetDiv = document.createElement('button')
// resetDiv.innerHTML = 'Reset'
// resetDiv.onclick = resetGame
// document.body.appendChild(resetDiv);

// const changeRocketDiv = document.createElement('button')
// changeRocketDiv.innerHTML = 'changeRocket'
// changeRocketDiv.onclick = changeRocketImg
// document.body.appendChild(changeRocketDiv);
