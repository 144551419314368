import * as PIXI from 'pixi.js'
import { settings } from '../settings'
// import { linesNum } from './gameUI'

export const pointsPerRow = Array.from({ length: 16 }, (_, index) => 3 + index)

export const secondIndex = (arr) => Math.floor(Math.random() * (arr.length - 1))

export const ballAndPointsNewRadius = linesNum => {
  return settings.point.initialRadius - (linesNum - 8) * 1
}

export const boxNewWidth = linesNum => settings.box.width - (linesNum - 8) * 2

export const fontSize = linesNum => settings.box.fontSizeInitial - (linesNum - 8) * 2

function createGradTexture () {
  // adjust it if somehow you need better quality for very very big images
  const quality = 256
  const canvas = document.createElement('canvas')

  canvas.width = quality
  canvas.height = 1

  const ctx = canvas.getContext('2d')

  const grd = ctx.createLinearGradient(0, 0, quality, 0)

  //  grd.addColorStop(0, 'rgba(255, 255, 255, 0.0)');
  grd.addColorStop(0.5, 'rgb(255,255,255)')
  //  grd.addColorStop(0.8, 'rgb(67,108,214)')
  // grd.addColorStop(0.7, 'rgb(145,35,255)')
  // grd.addColorStop(0.6, 'rgb(215,77,0)')
  // grd.addColorStop(0.5, 'rgb(169,43,79)')
  // grd.addColorStop(0.4, 'rgb(215,77,0)')
  // grd.addColorStop(0.3, 'rgb(145,35,255)')
  // // grd.addColorStop(0.2, 'rgb(67,108,214)')
  // grd.addColorStop(0.1, 'rgb(10,106,100)')

  ctx.fillStyle = grd
  ctx.fillRect(0, 0, quality, 1)

  return PIXI.Texture.from(canvas)
}

export const colors = [0x0A6A64, 0x436CD6, 0x9125FF, 0xD74D00, 0xA92A4E, 0xD74D00, 0x9125FF, 0x436CD6, 0x0A6A64, 0x0A6A64]
export function splitGradientTexture (numSprites) {
  const sprites = []
  console.log('numSprites', numSprites)
  for (let i = 0; i < numSprites; i++) {
    const texture = createGradTexture()
    const spriteWidth = texture.width / numSprites
    const spriteHeight = texture.height
    // console.log(percent)
    const sprite = new PIXI.Sprite(texture)
    sprite.tint = colors[Math.floor(i / numSprites * 10)]
    sprite.texture.frame = new PIXI.RoundedRectangle(
      i * spriteWidth,
      0,
      spriteWidth,
      spriteHeight,
      80,
      100
    )
    sprites.push(sprite)
  }
  return sprites
}
