// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.my-bets-section .table-responsive {
  min-height: 48px;
  max-height: calc(100vh - 370px);
  overflow: auto; }
  @media only screen and (max-width: 991.98px) {
    .my-bets-section .table-responsive {
      max-height: 230px; } }
  .my-bets-section .table-responsive .default-table thead {
    position: sticky;
    top: 0;
    z-index: 999; }
    .my-bets-section .table-responsive .default-table thead th {
      background-color: var(--bgSecondary); }
  .my-bets-section .table-responsive .default-table .date-td .bet-date {
    opacity: 0.6;
    font-size: 0.75rem; }
`, "",{"version":3,"sources":["webpack://./src/pages/NewCrashGame/components/BetsSidebar/MyBets/MyBets.scss"],"names":[],"mappings":"AAAA;EAEI,gBAAgB;EAChB,+BAA+B;EAC/B,cAAc,EAAA;EAEd;IANJ;MAOM,iBAAiB,EAAA,EAuBpB;EA9BH;IAgBQ,gBAAgB;IAChB,MAAM;IACN,YAAY,EAAA;IAlBpB;MAoBU,oCAAoC,EAAA;EApB9C;IAyBU,YAAY;IACZ,kBAAkB,EAAA","sourcesContent":[".my-bets-section {\n  .table-responsive {\n    min-height: 48px;\n    max-height: calc(100vh - 370px);\n    overflow: auto;\n\n    @media only screen and (max-width: 991.98px) {\n      max-height: 230px;\n    }\n\n    // &::-webkit-scrollbar {\n    //   width: 0;\n    // }\n\n    .default-table {\n      thead {\n        position: sticky;\n        top: 0;\n        z-index: 999;\n        th {\n          background-color: var(--bgSecondary);\n        }\n      }\n      .date-td {\n        .bet-date {\n          opacity: 0.6;\n          font-size: 0.75rem;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
