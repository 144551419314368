// import { METHOD_TYPES } from 'constants/index'
import axiosInstanceService, { microServices } from 'network/apis/index'
// import { microServices } from 'network/apis/microservice/index'
import { METHOD_TYPES } from 'utils/constants/index'

export const getBlackJackGameBetHistory = (params) => {
  return axiosInstanceService(METHOD_TYPES.get, 'blackjack-game/get-bets', {}, {
    server: microServices.SERVICE_URL_1,
    params
  })
}

export const blackJackGamePlaceBet = ({ data, isDemoGame }) => {
  return axiosInstanceService(METHOD_TYPES.post, 'blackjack-game/place-bet', data, {
    server: microServices.SERVICE_URL_1
    // headers: { 'demo-game': isDemoGame }
  })
}

export const blackJackGameHitBet = ({ data }) => {
  return axiosInstanceService(METHOD_TYPES.post, 'blackjack-game/hit', data, {
    server: microServices.SERVICE_URL_1
    // headers: { 'demo-game': isDemoGame }
  })
}

export const blackJackGameStandBet = ({ data }) => {
  return axiosInstanceService(METHOD_TYPES.post, 'blackjack-game/stand', data, {
    server: microServices.SERVICE_URL_1
    // headers: { 'demo-game': isDemoGame }
  })
}

export const blackJackGameDoubleBet = ({ data }) => {
  return axiosInstanceService(METHOD_TYPES.post, 'blackjack-game/double-bet', data, {
    server: microServices.SERVICE_URL_1
    // headers: { 'demo-game': isDemoGame }
  })
}

export const blackJackGameSplitBet = ({ data, isDemoGame }) => {
  return axiosInstanceService(METHOD_TYPES.post, 'blackjack-game/split-bet', data, {
    server: microServices.SERVICE_URL_1
    // headers: { 'demo-game': isDemoGame }
  })
}

export const blackJackUnfinishedBet = () => {
  return axiosInstanceService(METHOD_TYPES.get, 'blackjack-game/unfinished-bet', {}, {
    server: microServices.SERVICE_URL_1
    // headers: { 'demo-game': isDemoGame }
  })
}

export const blackJackPlaceInsuranceBet = ({ data }) => {
  return axiosInstanceService(METHOD_TYPES.post, 'blackjack-game/place-insurance', data, {
    server: microServices.SERVICE_URL_1
    // headers: { 'demo-game': isDemoGame }
  })
}

export const blackJackUpdateCurrentHandStatus = ({ data }) => {
  return axiosInstanceService(METHOD_TYPES.post, 'blackjack-game/update-current-hand-status', data, {
    server: microServices.SERVICE_URL_1
    // headers: { 'demo-game': isDemoGame }
  })
}

export const blackJackCheckFairness = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, 'blackjack-game/check-fairness', data, {
    server: microServices.SERVICE_URL_1
    // headers: { 'demo-game': isDemoGame }
  })
}

export const getMyBlackJackTransactionsService = (params) => {
  return axiosInstanceService(METHOD_TYPES.get, '/blackjack-game/my-bets', {}, {
    server: microServices.SERVICE_URL_1,
    params
  })
}

export const getBlackJackHighRollerTransactionsService = (params) => {
  return axiosInstanceService(METHOD_TYPES.get, '/blackjack-game/top-bets', {}, {
    server: microServices.SERVICE_URL_1,
    params
  })
}

export const getBlackJackTransactionsService = (params) => {
  return axiosInstanceService(METHOD_TYPES.get, '/blackjack-game/all-bets', {}, {
    server: microServices.SERVICE_URL_1,
    params
  })
}
