import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  autoBet, cashOutBet, getBetHistory, getLiveStats, getPreviousRoundState, getTopBets, openTile, placeBet,
  verifyProvableFairMineService
} from 'network/services/mineGame.service'
import { setRoundResult } from 'redux-store/redux/slices/mineGame.slice'
import { BET_RESULT } from 'utils/constants/index'

export const getMineGamePreviousRoundState = createAsyncThunk(
  'mineGame/get-previous-round-state', async (_, thunkApi) => {
    try {
      const res = await getPreviousRoundState()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0]?.description)
    }
  }
)

export const getMyBetsMineGame = createAsyncThunk(
  'mineGame/get-my-bets', async (data, thunkApi) => {
    try {
      const res = await getBetHistory(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0]?.description)
    }
  }
)

export const mineGamePlacedBet = createAsyncThunk(
  'mineGame/place-bet', async ({ payload }, thunkApi) => {
    try {
      const res = await placeBet(payload)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0]?.description)
    }
  }
)

export const mineGameAutoBet = createAsyncThunk(
  'mineGame/auto-bet', async ({ payload, normalTileAudio, mineTileAudio }, thunkApi) => {
    try {
      const res = await autoBet(payload)
      setTimeout(() => {
        if (res?.result === BET_RESULT.LOST) {
          thunkApi.dispatch(setRoundResult({ isLastWin: false, isLastLoss: true }))
        } else {
          thunkApi.dispatch(setRoundResult({ isLastWin: true, isLastLoss: false }))
        }
      }, 2000)
      res?.result === BET_RESULT.WON ? normalTileAudio?.play() : mineTileAudio?.play()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0]?.description)
    }
  }
)

export const mineGameOpenTile = createAsyncThunk(
  'mineGame/opened-tile', async (payload, thunkApi) => {
    try {
      const { normalTileAudio, mineTileAudio, tile } = payload
      const { minesVolume } = thunkApi?.getState()?.mineGame
      const res = await openTile({ tile })

      if (res?.mineTile) {
        setTimeout(() => {
          thunkApi.dispatch(setRoundResult({ isLastWin: false, isLastLoss: true }))
        }, 2000)
        mineTileAudio.volume = minesVolume
        mineTileAudio?.play()
      } else {
        normalTileAudio.volume = minesVolume
        normalTileAudio?.play()
      }
      res.tile = payload.tile
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0]?.description)
    }
  }
)

export const mineGameCashOut = createAsyncThunk(
  'mineGame/cash-out', async (_, thunkApi) => {
    try {
      const res = await cashOutBet()
      setTimeout(() => {
        thunkApi.dispatch(setRoundResult({ isLastWin: true, isLastLoss: false }))
      }, 2000)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0]?.description)
    }
  }
)

export const minGameTopBets = createAsyncThunk(
  'mineGame/top-bets', async (_, thunkApi) => {
    try {
      const res = await getTopBets()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0]?.description)
    }
  }
)

export const verifyProvableFairMine = createAsyncThunk('mine-game/check-provable-fair', async (payload, thunkApi) => {
  try {
    const res = await verifyProvableFairMineService(payload)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const getMineGameLiveStats = createAsyncThunk(
  'mineGame/get-mine-game-live-stats', async (_, thunkApi) => {
    try {
      const res = await getLiveStats()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0]?.description)
    }
  }
)
