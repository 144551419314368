// import { ProgressBar } from "@pixi/ui"
// import { settings } from "./settings"
// import { Container, Graphics, Text, TextStyle, Assets, Sprite } from "pixi.js"


// class CommonProgressBar extends Container {
//   constructor(config = null) {
//     super()
//     this.defaultArgs = {
//       animate: true,
//       value: 0,
//       vertical: false
//     }

//     this.options = { ...this.defaultArgs, ...config.bar }

//     // const background = new Graphics()
//     //   .beginFill(this.options.borderColor)
//     //   .drawRoundedRect(0, 0, this.options.width, this.options.height, this.options.radius)
//     //   .beginFill(this.options.backgroundColor)
//     //   .drawRoundedRect(this.options.border, this.options.border, this.options.width - (this.options.border * 2), this.options.height - (this.options.border * 2), this.options.radius)

//     // const filler = new Graphics()
//     //   .beginFill(this.options.borderColor)
//     //   .drawRoundedRect(0, 0, this.options.width, this.options.height, this.options.radius)
//     //   .beginFill(this.options.fillColor)
//     //   .drawRoundedRect(this.options.border, this.options.border, this.options.width - (this.options.border * 2), this.options.height - (this.options.border * 2), this.options.radius)


    

//     this.progressBar = new ProgressBar({
//       bg:  new Sprite(Assets.get('loaderBg')),
//       fill: new Sprite(Assets.get('loaderFiller')),
//       progress: this.options.value
//     })

//     const loadingTxtStyle = new TextStyle({ ...config.text, fontWeight: 'bold' })

//     this.progressText = new Text('', loadingTxtStyle)
//     this.progressText.visible = config.text.visible
//     this.progressText.position.set(
//       this.progressBar.width / 2,
//       this.progressBar.height
//     )

//     if(this.options.vertical) {
//       this.rotation = Math.PI / 2;
//       this.position.set(this.y, this.x)
//     }


//     this.addChild(this.progressBar, this.progressText)
//     this.pivot.set(
//       this.width / 2,
//       this.height / 2
//     )
//   }

//   setProgress(progress) {
//     this.progressBar.progress = Math.floor(progress * 100)
//     this.progressText.text = `${this.progressBar.progress}%`
//     this.progressText.x = this.progressBar.width / 2 - this.progressText.width / 2
//     if(Number(progress) === 1) this.visible = false
//   }
// }

// // To understand the progress bar checkout :- https://medium.com/@pratikkushwaha/creating-a-pixijs-progress-bar-with-pixi-ui-plugin-6f412dcae941

// // use it for assets loading
// //export const assetsProgressBar = new CommonProgressBar(settings.assetsProgress)

// // use it for showing progress for starting game
// //export const startGameProgressBar = new CommonProgressBar(settings.startGameProgress)
