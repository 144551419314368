import * as UI from './gameUI.js'
import {
  frontApp,
  backgroundApp
} from './appPixi.js'
import {
  loadingStopped
} from "./gameManager.js"
import {
  settings
} from './settings.js';
import * as PIXI from "pixi.js"
import gsap from 'gsap';
import PixiPlugin from 'gsap/PixiPlugin';
import {
  startGameProgressBar
} from './CommonProgressBar.js';
import {
  getRandomValue
} from './utility.js';

// registering the plugin
gsap.registerPlugin(PixiPlugin);

// giving the plugin a reference to the PIXI object
PixiPlugin.registerPIXI(PIXI);

const axisSpeed = 0;
let loadingSpeed = 0.5
let flewAwaySpeed = 5
let isExploading = false
let tl = gsap.timeline()

let boolStart = false;
let boolBlast = false;
let boolStartAxis = false;
let boolIsLoading = false

let floatCounter = 0
let startFloating = false

let graphX;
let graphY

const backgroundTargetPosition = {
  x: 0,
  y: 0
}

const cloudTargetPosition = {
  x: 0,
  y: 0
}

let backgroundSpeed = 0
let timer = 0



const gameValues = {
  xLineTop: null,
  yLineTop: null,
  xCurveFactor: null,
  yCurveFactor: null,
  xIncrement: 0,
}

let duration = 0;
let loadingStart = false
let startTime = Date.now()



function showLoadingScreen() {
  UI.containers.loadingContainer.visible = true
  UI.gameAssets.centerBigText.visible = false
}

function removeLoadingScreen() {
  UI.containers.loadingContainer.visible = false
 // startGameProgressBar.visible = true
 // loadingStopped()
}

function startLoading(t) {
  UI.containers.loadingContainer.visible = true
  boolIsLoading = true
 // console.log("t1="+t)
  timer = t*60
 // console.log("t2="+timer)
  loadingSpeed = UI.gameAssets.loadingBar1.width / (timer)
}

function startGame() {
  boolStartAxis = true
  boolStart = true
  removeLoadingScreen()

  UI.gameAssets.centerBigText.visible = true
  UI.gameAssets.rocket.visible = true
  UI.containers.axisContainer.visible = true

  if (window.innerWidth > window.innerHeight) {
    backgroundTargetPosition.x = -UI.gameAssets.background.width * 0.5
    backgroundTargetPosition.y = UI.gameAssets.background.height * 0.7
  } else {
    backgroundTargetPosition.x = -UI.gameAssets.background.width * 0.5
    backgroundTargetPosition.y = UI.gameAssets.background.height * 0.5
  }


  backgroundSpeed = 0.002 //0.001
}

function stopGame() {
  boolBlast = true
  UI.gameAssets.cashedoutText1.visible = false;
  UI.gameAssets.cashedoutText2.visible = false;
  UI.gameAssets.cashedoutText3.visible = false;


  //if (window.matchMedia("(orientation: portrait)").matches)
  //  UI.gameAssets.centerBigText.position.set(UI.appWidth * 0.5, UI.appHeight * 0.2)

  // UI.gameAssets.crashCenterText.visible = true
}

function changeRocket(n) {
  // ------ Assets got replaced! ------
  // UI.gameAssets.rocket.texture = UI.gameAssets.rocketTextures[n]
}

function setCenterText(s) {
  if (!UI.gameAssets.centerBigText.visible) UI.gameAssets.centerBigText.visible = true
  UI.gameAssets.centerBigText.text = s
}

function setValuesToInitial() {
  UI.gameAssets.loadingBar2.width = 0;
  UI.gameAssets.countdownText.text = '5'
  showLoadingScreen()

  UI.gameAssets.centerBigText.text = ''
  UI.gameAssets.crashCenterText.visible = false

  UI.gameAssets.rocket.visible = false

  UI.gameAssets.topLineGraph.clear()
  UI.gameAssets.bottomLineGraph.clear()

  gameValues.xLineTop = 0
  gameValues.yLineTop = 0
  gameValues.xCurveFactor = 0
  gameValues.yCurveFactor = 0
  gameValues.xIncrement = 0

  boolStartAxis = false
  boolStart = false
  boolBlast = false
  boolIsLoading = false
  floatCounter = 0
  startFloating = false

  UI.containers.axisContainer.visible = false

  backgroundTargetPosition.x = 0
  backgroundTargetPosition.y = 0
  backgroundSpeed = 0.2

  isExploading = false
  removeLosingTextSetup()
  UI.gameAssets.rocket.alpha = 1
  UI.gameAssets.rocket.rotation = 0

  UI.gameAssets.nearestPlane.position.set(
    0 - UI.gameAssets.nearestPlane.width,
    0 - UI.gameAssets.nearestPlane.height
  )

  UI.gameAssets.farthestPlane.position.set(
    0 - UI.gameAssets.farthestPlane.width,
    0 - UI.gameAssets.farthestPlane.height
  )

  tl.clear(true)

  // backgroundTargetPosition.x = -UI.gameAssets.background.width *0.5
  // backgroundTargetPosition.y = UI.gameAssets.background.height * 0.7
  UI.containers.cloudContainer.position.set(0, -backgroundApp.screen.height * 2.5)

  UI.gameAssets.cashedoutText1.visible = false;
  UI.gameAssets.cashedoutText2.visible = false;
  UI.gameAssets.cashedoutText3.visible = false;

  if (window.matchMedia("(orientation: portrait)").matches) {
    UI.gameAssets.centerBigText.position.set(UI.appWidth * 0.5, UI.gameAssets.centerBigText.height*0.4)
    UI.gameAssets.centerSmallText.position.set(
      UI.gameAssets.centerBigText.x ,
      UI.gameAssets.centerBigText.position.y - 10
    )

  } else {
    UI.gameAssets.centerBigText.position.set(UI.appWidth * 0.88, UI.gameAssets.centerBigText.height*0.4)
    UI.gameAssets.centerSmallText.position.set(
      UI.gameAssets.centerBigText.x - UI.gameAssets.centerBigText.width,
      UI.gameAssets.centerBigText.position.y
    )
  }

UI.gameAssets.centerBigText.style.stroke="#E3FCFF"
UI.gameAssets.centerBigText.style.strokeThickness=10
UI.containers.loadingContainer.visible = false


}

function setCashoutText(num1, num2) {

  UI.gameAssets.cashedoutText1.visible = true;
  UI.gameAssets.cashedoutText2.visible = true;
  UI.gameAssets.cashedoutText3.visible = true;

  UI.gameAssets.cashedoutText2.text = num1;
  UI.gameAssets.cashedoutText3.text = "MULTIPLIER X" + num2;



}

function rocketGapCalculate() {
  if (window.matchMedia("(orientation: portrait)").matches) {
    return settings.axisGap * 1.4
  }
  return settings.axisGap * 2
}

function xPosition(x) {

  let maxWidth = UI.appWidth - rocketGapCalculate() - (settings.xCorrectedPos * (UI.appWidth / 1920))
  const xVal = x * (maxWidth / settings.totalDivisions)

  if (xVal >= maxWidth) {
    startFloating = true
  }

  return xVal < maxWidth ? xVal : maxWidth
}

function yValue(y) {
  // return Math.pow(2, y * 0.09)
  return y
}

function yPosition(y) {
  let maxHeight = UI.appHeight - rocketGapCalculate() - (settings.yCorrectedPos * (UI.appHeight / 1080))
  const yVal = y * (maxHeight / settings.totalDivisions)

  return yVal < maxHeight ? yVal : maxHeight
}

function yLineTopCorrected(y) {
  return UI.appHeight - y
}

export function distance(currentPos, targetPos) {
  let distance = Math.pow((targetPos.x - currentPos.x), 2) + Math.pow((targetPos.y - currentPos.y), 2)
  distance = Math.sqrt(distance)
  return distance;
}

export function moveTowards(currentPos, targetPos, speed) {
  let xDirection = targetPos.x - currentPos.x;
  let yDirection = targetPos.y - currentPos.y;
  currentPos.x += (xDirection * speed)
  currentPos.y += (yDirection * speed)
  return currentPos;
}

function losingTextSetup() {
  if (!window.matchMedia("(orientation: portrait)").matches) {
    // UI.gameAssets.centerSmallText.visible = true
    UI.gameAssets.centerSmallText.x = (UI.gameAssets.centerBigText.x -
      (UI.gameAssets.centerBigText.width / 2) * 1.25 -
      (UI.gameAssets.centerSmallText.width / 2))
    UI.gameAssets.centerBigText.style.fill = "#A92A4E"

  } else {
    // UI.gameAssets.centerSmallText.visible = true
    UI.gameAssets.centerBigText.style.fill = "#A92A4E"
  }

  UI.gameAssets.centerBigText.style.stroke="#FFF5F8"
UI.gameAssets.centerBigText.style.strokeThickness=10


}

function removeLosingTextSetup() {
  UI.gameAssets.centerSmallText.visible = false
  UI.gameAssets.centerBigText.style.fill = "#48A29C"
}

async function showNearestPlane() {
  UI.gameAssets.nearestPlane.position.set(
    0 - UI.gameAssets.nearestPlane.width,
    0 - UI.gameAssets.nearestPlane.height
  )

  UI.gameAssets.nearestPlane.isFlying = true
  UI.gameAssets.nearestPlane.isCrashed = false
  UI.gameAssets.nearestPlane.alpha = 0.5
  UI.gameAssets.nearestPlane.rotation = Math.PI / 4

  await gsap.to(UI.gameAssets.nearestPlane, {
    alpha: 1,
    x: frontApp.stage.width * 0.25,
    y: frontApp.stage.height * 0.25,
    rotation: 0,
    duration: 2
  })

  while (!UI.gameAssets.nearestPlane.isCrashed) {
    await gsap.to(UI.gameAssets.nearestPlane, {
      y: frontApp.stage.height * 0.2,
      duration: 7
    })

    await gsap.to(UI.gameAssets.nearestPlane, {
      y: frontApp.stage.height * 0.3,
      duration: 7
    })
  }
}

async function showFarthestPlane() {
  UI.gameAssets.farthestPlane.position.set(
    0 - UI.gameAssets.farthestPlane.width,
    0 - UI.gameAssets.farthestPlane.height
  )

  UI.gameAssets.farthestPlane.isFlying = true
  UI.gameAssets.farthestPlane.isCrashed = false
  UI.gameAssets.farthestPlane.alpha = 0.5
  UI.gameAssets.farthestPlane.rotation = Math.PI / 4

  await gsap.to(UI.gameAssets.farthestPlane, {
    pixi: {
      alpha: 1,
      x: frontApp.stage.width * 0.3,
      y: frontApp.stage.height * 0.5,
      rotation: 0
    },
    duration: 2
  })

  while (!UI.gameAssets.farthestPlane.isCrashed) {
    await gsap.to(UI.gameAssets.farthestPlane, {
      y: frontApp.stage.height * 0.45,
      duration: 7
    })

    await gsap.to(UI.gameAssets.farthestPlane, {
      y: frontApp.stage.height * 0.55,
      duration: 7
    })
  }
}

async function crashNearestPlane() {
  UI.gameAssets.nearestPlane.isFlying = false
  UI.gameAssets.nearestPlane.isCrashed = true

  await gsap.to(UI.gameAssets.nearestPlane, {
    rotation: Math.PI / 6,
    duration: 0.3
  })
  await gsap.to(UI.gameAssets.nearestPlane, {
    alpha: 0,
    x: frontApp.stage.width * 0.5,
    y: frontApp.stage.height,
    duration: 1.5
  })

}

async function crashFarthestPlane() {
  UI.gameAssets.farthestPlane.isFlying = false
  UI.gameAssets.farthestPlane.isCrashed = true

  await gsap.to(UI.gameAssets.farthestPlane, {
    rotation: Math.PI / 6,
    duration: 0.3
  })
  await gsap.to(UI.gameAssets.farthestPlane, {
    alpha: 0,
    x: frontApp.stage.width * 0.5,
    y: frontApp.stage.height,
    duration: 1.5
  })
}

async function crashMainPlane() {
  await gsap.to(UI.gameAssets.rocket, {
    rotation: Math.PI / 4,
    duration: 0.4
  })

  await gsap.to(UI.gameAssets.rocket, {
    alpha: 0,
    duration: 0.8
  })
}

frontApp.ticker.add((delta) => {

  //Loading=======================
  if (boolIsLoading) {
    if (timer > 0) {
      timer -= delta*1.3
     // console.log(timer)
       UI.gameAssets.countdownText.text = Math.max(0, (timer/100).toFixed(0))
      if (UI.gameAssets.loadingBar2.width < UI.gameAssets.loadingBar1.width * 0.96)
        UI.gameAssets.loadingBar2.width += loadingSpeed*1.3
    } else {
      boolIsLoading = false
       //removeLoadingScreen()
       loadingStopped()
    }
  }


  if (boolBlast) {
    UI.containers.rocketContainer.position.set(
      UI.containers.rocketContainer.position.x + flewAwaySpeed * 0.15 * delta,
      UI.containers.rocketContainer.position.y + flewAwaySpeed * 0.5 * delta
    )
    if (!isExploading) {
      UI.gameAssets.explosionAnim.visible = true
      UI.gameAssets.explosionAnim.gotoAndPlay(0)
      losingTextSetup()
      isExploading = true
      crashMainPlane()
      if (UI.gameAssets.farthestPlane.isFlying) {
        crashFarthestPlane()
      }

      if (UI.gameAssets.nearestPlane.isFlying) {
        crashNearestPlane()
      }
    }
    return;
  }

  if (boolStartAxis) {
    UI.gameAssets.axisDots.x.forEach((element) => {
      element.position.x -= delta * axisSpeed;
      if (element.position.x < UI.appWidth * 0.015) element.position.x = UI.appWidth;
    });
    UI.gameAssets.axisDots.y.forEach((element) => {
      element.position.y += delta * axisSpeed;
      if (element.position.y > UI.appHeight * 0.985) element.position.y = 0;
    });
  }

  if (boolStart) {
    gameValues.xIncrement += 0.1 * (settings.graphSpeed / 100) * delta
    gameValues.xLineTop = xPosition(gameValues.xIncrement)
    gameValues.yLineTop = yLineTopCorrected(yPosition(yValue(gameValues.xIncrement)))

    if (startFloating) {
      if (!UI.gameAssets.farthestPlane.isFlying) showFarthestPlane()
      if (!UI.gameAssets.nearestPlane.isFlying) showNearestPlane()
      floatCounter += 0.01 * delta
      UI.containers.rocketContainer.position.set(UI.containers.rocketContainer.position.x, UI.containers.rocketContainer.position.y - Math.cos(floatCounter) * 0.3)
    } else {
      UI.containers.rocketContainer.position.set(gameValues.xLineTop + settings.xCorrectedPos * (UI.appWidth / 1920),
        gameValues.yLineTop - settings.yCorrectedPos * (UI.appWidth / 1920))
    }


    UI.gameAssets.topLineGraph.clear()
    UI.gameAssets.topLineGraph.lineStyle(5, 0x9633FA, 0.001)

    graphX = boolBlast ? graphX : UI.containers.rocketContainer.x - UI.gameAssets.rocket.width * 0.3 //40
    graphY = boolBlast ? graphY : UI.containers.rocketContainer.y + UI.gameAssets.rocket.height * 0.4 //60

    gameValues.xCurveFactor = graphX / (10 / 6)
    gameValues.yCurveFactor = UI.appHeight

    UI.gameAssets.topLineGraph.moveTo(0, UI.appHeight)
    UI.gameAssets.topLineGraph.quadraticCurveTo(
      gameValues.xCurveFactor,
      gameValues.yCurveFactor,
      graphX,
      graphY
    )

    UI.gameAssets.bottomLineGraph.clear()
    UI.gameAssets.bottomLineGraph.beginFill(0xBA5635, 0.1)

    UI.gameAssets.bottomLineGraph.moveTo(0, UI.appHeight)
    UI.gameAssets.bottomLineGraph.quadraticCurveTo(
      gameValues.xCurveFactor,
      gameValues.yCurveFactor,
      graphX,
      graphY
    )

    UI.gameAssets.bottomLineGraph.lineTo(graphX, UI.appHeight)
    UI.gameAssets.bottomLineGraph.closePath()
    UI.gameAssets.bottomLineGraph.endFill()
  }


})

backgroundApp.ticker.add((delta) => {
  if (boolBlast) return
  //if (distance(UI.containers.bgContainer.position, backgroundTargetPosition) > 0.5)

  UI.containers.BgContainer.position = moveTowards(UI.containers.BgContainer.position, backgroundTargetPosition, delta * backgroundSpeed)
  UI.containers.cloudContainer.position = moveTowards(UI.containers.cloudContainer.position, {
    x: 0,
    y: -backgroundApp.screen.height * 0.2
  }, delta * 0.002)

  for (let i = 0; i < UI.gameAssets.clouds.length; i++) {
    if (UI.gameAssets.clouds[i].sprite.position.x < -backgroundApp.screen.width * 0.5 - UI.gameAssets.clouds[i].sprite.width) {
      UI.gameAssets.clouds[i].sprite.position.x = UI.gameAssets.clouds[i].sprite.width + backgroundApp.screen.width * 0.5
    }
    UI.gameAssets.clouds[i].sprite.position.set(UI.gameAssets.clouds[i].sprite.position.x - (UI.gameAssets.clouds[i].speed) * delta,
      UI.gameAssets.clouds[i].sprite.position.y)
  }


})


export {
  startGame,
  setValuesToInitial,
  stopGame,
  startLoading,
  changeRocket,
  setCenterText,
  setCashoutText
}
