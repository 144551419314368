// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal {
  background-color: var(--black-25); }
  .modal .modal-dialog .modal-content {
    background-color: var(--brown-950);
    border-radius: 0.937rem; }
    .modal .modal-dialog .modal-content .modal-body {
      position: relative; }
      .modal .modal-dialog .modal-content .modal-body .btn-close-modal {
        position: absolute;
        top: 1rem;
        right: 1rem; }
`, "",{"version":3,"sources":["webpack://./src/components/ui-kit/Popup/Popup.scss"],"names":[],"mappings":"AAAA;EACE,iCAAiC,EAAA;EADnC;IAIM,kCAAkC;IAClC,uBAAuB,EAAA;IAL7B;MAOQ,kBAAkB,EAAA;MAP1B;QASU,kBAAkB;QAClB,SAAS;QACT,WAAW,EAAA","sourcesContent":[".modal {\n  background-color: var(--black-25);\n  .modal-dialog {\n    .modal-content {\n      background-color: var(--brown-950);\n      border-radius: 0.937rem;\n      .modal-body {\n        position: relative;\n        .btn-close-modal {\n          position: absolute;\n          top: 1rem;\n          right: 1rem;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
