import { createSlice } from '@reduxjs/toolkit'
import { escapeCrashBet, getAllRoundHistory, getMyBets, getMyBetsPaginated, getMyStats, getPreviousRoundBets, getTopBets, placedBetCrashGame, verifyProvableFairCrash } from 'redux-store/thunk/crashGame.thunk'
import { APPEND_TYPES, CRASH_TOASTER_STATES, DEFAULT_MY_BETS_LIMIT } from 'utils/constants/index'

const initialState = {
  roundBetResult: null,
  betHistoryData: [],
  resultData: [],
  currentRoundId: '',
  placingOrCashoutLoading: [false, false],
  placedCrashBetId: [null, null],
  currentPlacedBet: [null, null],
  allRoundHistoryData: [],
  allRoundLoading: false,
  myBetsData: {
    count: 0,
    rows: []
  },
  myBetsDataPaginated: {
    count: 0,
    rows: []
  },
  myBetsDataPaginatedLoading: false,
  myBetsLoading: false,
  topBetsData: [],
  topBetsLoading: false,
  playerStats: null,
  chooseCharacterOpen: true,
  provableFairData: null,
  previousBetsData: [],
  myStats: {
    maxMultiplier: 0,
    totalRounds: 0,
    avgMultiplier: 0
  },
  toasterStates: CRASH_TOASTER_STATES.NONE,
  toasterData: null
}

const {
  actions: {
    setCrashToaster,
    setPlacedBetsData,
    setResultData,
    setCurrentRoundId,
    setPlacedCrashBetId,
    setChooseCharacterOpen,
    setProvableFairData,
    appendCurrentBetsToMyBets,
    setPlacingOrCashoutLoading,
    setCurrentPlacedBet,
    setPlayerStats
  },
  reducer
} = createSlice({
  name: 'crashGame',
  initialState,
  reducers: {
    setCrashToaster: (state, action) => {
      return {
        ...state,
        toasterStates: action.payload?.type,
        toasterData: action.payload?.data
      }
    },
    setPlayerStats: (state, action) => {
      return {
        ...state,
        myStats: {
          ...state.myStats,
          ...action.payload
        }
      }
    },
    setPlacingOrCashoutLoading: (state, action) => {
      if (action.payload?.index !== undefined && action.payload?.value !== undefined) {
        const index = action.payload?.index ?? 0
        const _loading = [...state.placingOrCashoutLoading]
        _loading[index] = action.payload.value
        return {
          ...state,
          placingOrCashoutLoading: _loading
        }
      }
      return state
    },
    setPlacedBetsData: (state, action) => {
      return {
        ...state,
        betHistoryData: action.payload
      }
    },
    setResultData: (state, action) => {
      return {
        ...state,
        resultData: action.payload
      }
    },
    setCurrentRoundId: (state, action) => {
      return {
        ...state,
        currentRoundId: action.payload
      }
    },
    setChooseCharacterOpen: (state, action) => {
      return {
        ...state,
        chooseCharacterOpen: action.payload
      }
    },
    setProvableFairData: (state, action) => {
      return {
        ...state,
        provableFairData: action.payload
      }
    },
    setCurrentPlacedBet: (state, action) => {
      if (!action.payload?.reset) {
        const { id, betDetails } = action.payload
        const newPlacedCrashBetId = [...state.currentPlacedBet]
        newPlacedCrashBetId[id] = betDetails
        return {
          ...state,
          currentPlacedBet: newPlacedCrashBetId
        }
      } else {
        return {
          ...state,
          currentPlacedBet: initialState.currentPlacedBet
        }
      }
    },
    setPlacedCrashBetId: (state, action) => {
      if (!action.payload?.reset) {
        const { id, betId } = action.payload
        const newPlacedCrashBetId = [...state.placedCrashBetId]
        newPlacedCrashBetId[id] = betId
        return {
          ...state,
          placedCrashBetId: newPlacedCrashBetId
        }
      } else {
        return {
          ...state,
          placedCrashBetId: initialState.placedCrashBetId
        }
      }
    },
    appendCurrentBetsToMyBets: (state, action) => {
      if (action.payload?.type === APPEND_TYPES.ESCAPE) {
        const escapedBet = action.payload.data
        const betExist = state.myBetsData?.rows?.some(bet => bet?.id === escapedBet?.[0]?.id)
        if (!betExist) {
          return {
            ...state,
            myBetsData: {
              count: state.myBetsData.count + (escapedBet?.length ?? 0),
              rows: [...escapedBet, ...state.myBetsData?.rows].slice(0, DEFAULT_MY_BETS_LIMIT)
            }
          }
        }
      } else if (action.payload?.type === APPEND_TYPES.ROUND_STOPPED) {
        const userId = action.payload?.userId
        if (userId) {
          const alreadyPresentBetIds = state.myBetsData?.rows.map(bet => +bet.id)
          const currentPlacedBets = [...state.betHistoryData].filter(bet => (+bet?.userId === +userId && !alreadyPresentBetIds.includes(+bet.id)))
          return {
            ...state,
            myBetsData: {
              count: state.myBetsData.count + (currentPlacedBets?.length ?? 0),
              rows: [...currentPlacedBets, ...state.myBetsData?.rows].slice(0, DEFAULT_MY_BETS_LIMIT)
            }
          }
        }
      }
      return state
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(placedBetCrashGame.pending, (state, action) => {
        return {
          ...state
        }
      })
      .addCase(placedBetCrashGame.rejected, (state, action) => {
        return {
          ...state
        }
      })
      .addCase(escapeCrashBet.rejected, (state, action) => ({
        ...state,
        roundBetResult: null
      }))
      .addCase(escapeCrashBet.pending, (state, action) => ({
        ...state
      }))
      .addCase(getAllRoundHistory.fulfilled, (state, action) => {
        return {
          ...state,
          allRoundHistoryData: action.payload?.history,
          allRoundLoading: false
        }
      })
      .addCase(getAllRoundHistory.rejected, (state, action) => {
        return {
          ...state,
          allRoundHistoryData: [],
          allRoundLoading: false
        }
      })

      .addCase(getAllRoundHistory.pending, (state, action) => {
        return {
          ...state,
          allRoundHistoryData: action.payload,
          allRoundLoading: true
        }
      })
      .addCase(getMyStats.pending, (state, action) => {
        return {
          ...state
        }
      })
      .addCase(getMyStats.rejected, (state, action) => {
        return {
          ...state
        }
      })
      .addCase(getMyStats.fulfilled, (state, action) => {
        return {
          ...state,
          myStats: {
            ...state.myStats,
            ...action.payload
          }
        }
      })
      .addCase(getMyBets.pending, (state, action) => {
        return {
          ...state,
          myBetsLoading: true

        }
      })
      .addCase(getMyBets.rejected, (state, action) => {
        return {
          ...state,
          myBetsData: initialState.myBetsData,
          myBetsLoading: false

        }
      })
      .addCase(getMyBets.fulfilled, (state, action) => {
        return {
          ...state,
          myBetsData: action.payload,
          myBetsLoading: false

        }
      })
      .addCase(getMyBetsPaginated.pending, (state, action) => {
        return {
          ...state,
          myBetsDataPaginatedLoading: true

        }
      })
      .addCase(getMyBetsPaginated.rejected, (state, action) => {
        return {
          ...state,
          myBetsDataPaginated: initialState.myBetsDataPaginated,
          myBetsDataPaginatedLoading: false

        }
      })
      .addCase(getMyBetsPaginated.fulfilled, (state, action) => {
        return {
          ...state,
          myBetsDataPaginated: action.payload,
          myBetsDataPaginatedLoading: false

        }
      })
      .addCase(getPreviousRoundBets.pending, (state, action) => {
        return {
          ...state
        }
      })
      .addCase(getPreviousRoundBets.rejected, (state, action) => {
        return {
          ...state,
          previousBetsData: []

        }
      })
      .addCase(getPreviousRoundBets.fulfilled, (state, action) => {
        return {
          ...state,
          previousBetsData: action.payload
        }
      })
      .addCase(getTopBets.pending, (state, action) => {
        return {
          ...state,
          topBetsLoading: true
        }
      })
      .addCase(getTopBets.fulfilled, (state, action) => {
        return {
          ...state,
          topBetsData: action.payload,
          topBetsLoading: false
        }
      })
      .addCase(getTopBets.rejected, (state, action) => {
        return {
          ...state,
          topBetsData: [],
          topBetsLoading: false
        }
      })
      .addCase(verifyProvableFairCrash.pending, (state, payload) => ({
        ...state,
        loading: true
      }))
      .addCase(verifyProvableFairCrash.fulfilled, (state, { payload }) => ({
        ...state,
        provableFairData: payload,
        loading: false
      }))
      .addCase(verifyProvableFairCrash.rejected, (state, payload) => ({
        ...state,
        loading: false
      }))
  }
})

export default reducer
export {
  setCrashToaster,
  setPlacedBetsData,
  setResultData,
  setCurrentRoundId,
  setPlacedCrashBetId,
  setChooseCharacterOpen,
  setProvableFairData,
  appendCurrentBetsToMyBets,
  setPlacingOrCashoutLoading,
  setCurrentPlacedBet,
  setPlayerStats
}
