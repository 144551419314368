import { ReactComponent as CopyIcon } from './copy.svg'
import { ReactComponent as CrossIcon } from './cross-icon.svg'
import { ReactComponent as HistoryIcon } from './history-icon.svg'
import { ReactComponent as MinusIcon } from './minus-icon.svg'
import { ReactComponent as MusicIcon } from './music.svg'
import { ReactComponent as PlusIcon } from './plus-icon.svg'
import { ReactComponent as RefreshIcon } from './refresh-icon.svg'
import { ReactComponent as RightArrowIcon } from './right-arrow.svg'
import { ReactComponent as SoundIcon } from './sound.svg'
import { ReactComponent as TogglerIcon } from './toggler-icon.svg'
import { ReactComponent as DeleteIcon } from './trash-ican.svg'
import { ReactComponent as UserIcon } from './user.svg'
import { ReactComponent as UpArrowIcon } from './up-arrow.svg'
import { ReactComponent as SettingsIcon } from './settings-icon.svg'
import { ReactComponent as SettingsOutlineIcon } from './setting-outline.svg'
import { ReactComponent as MinimizeIcon } from './Minimize.svg'
import { ReactComponent as MaximizeIcon } from './Maximize.svg'
import { ReactComponent as CrossCircleIcon } from './cross-circle.svg'
import { ReactComponent as StrategyIcon } from './strategy.svg'
import { ReactComponent as PercentIcon } from './percent-circle.svg'
// new icons components
import { ReactComponent as SettingsFillIcon } from './settings-fill-icon.svg'
import { ReactComponent as MaximizeFillIcon } from './maximize-fill-icon.svg'
import { ReactComponent as VolumeFillIcon } from './volume-loud-fill-icon.svg'
import { ReactComponent as ArrowSingleUpIcon } from './arrow-single-up.svg'
import { ReactComponent as ArrowDoubleUpIcon } from './arrow-double-up.svg'
import { ReactComponent as ArrowTripleUpIcon } from './arrow-triple-up.svg'
import { ReactComponent as LeftFillArrowIcon } from './left-fill-arrow.svg'
import { ReactComponent as RightFillArrowIcon } from './right-fill-arrow.svg'
import { ReactComponent as AutoBetIconIcon } from './auto-bet-icon.svg'
import { ReactComponent as AutoBetActiveIcon } from './auto-bet-icon-active.svg'
import { ReactComponent as CrashSettingsIcon } from './crash-settings-icon.svg'
import { ReactComponent as UsersFillIcon } from './users-icon.svg'
import { ReactComponent as ProfileEditIcon } from './edit-profile.svg'
import { ReactComponent as UserPlightIcon } from './user-plight-icon.svg'
import { ReactComponent as MenuNavIcon } from './menu-icon.svg'
import { ReactComponent as ArrowFillUpIcon } from './arrow-top-fill-circle.svg'
import { ReactComponent as UsersGroupIcon } from './users-group.svg'
import { ReactComponent as PlayFillIcon } from './play-right.svg'
import { ReactComponent as MusicNoteIcon } from './music-note.svg'
import { ReactComponent as VolumeFillProfileIcon } from './volume-fill-icon.svg'
import { ReactComponent as CryptoCurrencyIcon } from './Cryptocurrency.svg'
import { ReactComponent as InfinityIcon } from './ph_infinity.svg'
import { ReactComponent as StatisticsIcon } from './statistics.svg'
import { ReactComponent as WhiteBtnIcon } from './white-btn.svg'
import { ReactComponent as CopyFillIcon } from './Copy-fill.svg'
import { ReactComponent as InfoCircleIcon } from './Info-Circle.svg'
import { ReactComponent as CrownlIcon } from './Crown.svg'
import { ReactComponent as BoltIcon } from './Bolt.svg'
import { ReactComponent as DoubleIcon } from './double-icon.svg'
import { ReactComponent as StandIcon } from './stand-icon.svg'
import { ReactComponent as SplitIcon } from './split-icon.svg'
import { ReactComponent as HitIcon } from './hit-icon.svg'
import { ReactComponent as UndoIcon } from './Undo.svg'
import { ReactComponent as ClearIcon } from './Clear.svg'

// icons export
export {
  CopyIcon,
  CrossIcon,
  HistoryIcon,
  MinusIcon,
  MusicIcon,
  PlusIcon,
  RefreshIcon,
  RightArrowIcon,
  SoundIcon,
  TogglerIcon,
  DeleteIcon,
  UserIcon,
  UpArrowIcon,
  SettingsIcon,
  SettingsOutlineIcon,
  MinimizeIcon,
  MaximizeIcon,
  CrossCircleIcon,
  StrategyIcon,
  PercentIcon,
  // new icons
  SettingsFillIcon,
  MaximizeFillIcon,
  VolumeFillIcon,
  ArrowSingleUpIcon,
  ArrowDoubleUpIcon,
  ArrowTripleUpIcon,
  LeftFillArrowIcon,
  RightFillArrowIcon,
  AutoBetIconIcon,
  AutoBetActiveIcon,
  CrashSettingsIcon,
  UsersFillIcon,
  ProfileEditIcon,
  UserPlightIcon,
  MenuNavIcon,
  ArrowFillUpIcon,
  UsersGroupIcon,
  PlayFillIcon,
  MusicNoteIcon,
  VolumeFillProfileIcon,
  CryptoCurrencyIcon,
  InfinityIcon,
  StatisticsIcon,
  WhiteBtnIcon,
  CopyFillIcon,
  InfoCircleIcon,
  CrownlIcon,
  BoltIcon,
  DoubleIcon,
  StandIcon,
  SplitIcon,
  HitIcon,
  UndoIcon,
  ClearIcon
}
