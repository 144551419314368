import React from 'react'
import './MyBets.scss'
import Image from 'components/ui-kit/Image/index'
import { useSelector } from 'react-redux'
import { BET_RESULT, COIN_CURRENCY_ID } from 'utils/constants/index'
import { useTranslation } from 'react-i18next'
import { formatDate, formatTime, getPrecision, numberWithCommas } from 'utils/common-functions/index'

const MyBets = ({
  isSideBar,
  betHistoryData
}) => {
  const { myBetsData } = useSelector((state) => state.crashGame)
  const { t } = useTranslation()

  return (
    <div className='text-white my-bets-section'>
      {/* {isSideBar &&
        <div className='d-flex justify-content-between align-items-center mb-2'>
          <div>
            My total bets:
            <h6 className='total-bets-count mb-0'>{myBetsData?.count}</h6>
          </div>
        </div>} */}
      {/* table */}
      <div className='table-responsive'>
        <table className='table default-table text-white mb-0'>
          {/* <thead>
            <tr>
              <th>Date</th>
              <th>Bet</th>
              <th>Multi.</th>
              <th className='text-end'>Cashout</th>
            </tr>
          </thead> */}
          {isSideBar
            ? (
              <tbody>
                {
              myBetsData?.rows?.length
                ? (
                    myBetsData?.rows?.map(data => {
                      return (
                        <tr key={data?.id.toString()}>
                          <td>
                            <div className='date-td'>
                              <div className='bet-date'>{formatDate(data?.createdAt)}</div>
                              <div className='bet-time'>{formatTime(data?.createdAt)}</div>
                            </div>
                          </td>
                          <td>
                            ${numberWithCommas(getPrecision(data?.betAmount))}
                          </td>
                          <td className='text-center'>{data?.escapeRate.toFixed(2)}x</td>
                          <td className={(data?.currencyId === COIN_CURRENCY_ID.USD) ? 'text-end text-green-900' : 'text-end text-golden'}> {data?.result === BET_RESULT.WON ? `$${numberWithCommas(getPrecision(data?.winningAmount))}` : '$0'}</td>
                        </tr>
                      )
                    }
                    )
                  )
                : (
                  <tr className='text-center'>
                    <td colSpan='6'>
                      {(t('noData'))}
                    </td>
                  </tr>
                  )
              }
              </tbody>)
            : (
              <tbody>
                {
              betHistoryData?.length
                ? (
                    betHistoryData?.map(data => {
                      return (
                        <tr key={data?.id.toString()}>
                          <td>
                            <div className='date-td'>
                              {formatDate(data?.createdAt)} <br />
                              {formatTime(data?.createdAt)}
                            </div>
                          </td>
                          <td>
                            <Image src={data?.currencyId === COIN_CURRENCY_ID.USD ? '/assets/images/common/switch-yellow-coin.png' : '/assets/images/common/switch-green-coin.png'} alt='logo' className='w-18 me-1 float-start' />
                            {numberWithCommas(getPrecision(data?.betAmount))} {data?.currencyId === COIN_CURRENCY_ID.USD ? 'USD' : 'EUR'}
                          </td>
                          <td className='text-center'><span className='badge bg-orange'>{data?.escapeRate.toFixed(2)}x</span></td>
                          <td className={(data?.currencyId === COIN_CURRENCY_ID.USD) ? 'text-end text-golden' : 'text-end text-green'}><Image src={data?.currencyId === COIN_CURRENCY_ID.USD ? '/assets/images/common/switch-yellow-coin.png' : '/assets/images/common/switch-green-coin.png'} alt='logo' className='w-18 float-start' /> {data?.result === BET_RESULT.WON ? `${data?.currencyId === COIN_CURRENCY_ID.USD ? 'USD' : 'EUR'} ${numberWithCommas(getPrecision(data?.winningAmount))}` : '-'}</td>
                        </tr>
                      )
                    }
                    )
                  )
                : (
                  <tr className='text-center'>
                    <td colSpan='6'>
                      {(t('noData'))}
                    </td>
                  </tr>
                  )
            }
              </tbody>)}
        </table>
      </div>
    </div>
  )
}
export default MyBets
