import { createAsyncThunk } from '@reduxjs/toolkit'
import { pixiResetRoulette, pixiStartRoulette, pixiStopRoulette } from './pixi-scripts/bridge'
import { getMyBetsRouletteService, placeBetRouletteGameService, rouletteCheckFairnessService } from './rouletteGame.service'
import { setNewClientSeed, setServerSeedHash } from 'redux-store/redux/slices/gameSettings.slice'
import { delay, generateClientSeed } from 'utils/common-functions/index'
import { openErrorToaster } from 'utils/common-services/toaster.service'

export const placedBetRouletteGame = createAsyncThunk('roulette-game/place-bet',
  async ({ payload }, thunkApi) => {
    try {
      pixiStartRoulette()
      const res = await placeBetRouletteGameService(payload)
      if (res?.nextServerSeedHash) {
        const newClientSeed = generateClientSeed()
        thunkApi.dispatch(setNewClientSeed(newClientSeed))
        thunkApi.dispatch(setServerSeedHash(res.nextServerSeedHash))
      }
      if (res?.winningNumber !== undefined) {
        await delay(800)
        pixiStopRoulette(res.winningNumber)
      } else {
        pixiResetRoulette()
      }
      return res
    } catch (error) {
      pixiResetRoulette()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getMyBetsRoulette = createAsyncThunk('roulette-game/myBets', async (params, thunkApi) => {
  try {
    const res = await getMyBetsRouletteService(params)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const checkFairnessRouletteGame = createAsyncThunk('roulette-game/check-fairness', async (payload, thunkApi) => {
  try {
    const res = await rouletteCheckFairnessService(payload)
    return res
  } catch (error) {
    openErrorToaster({ message: error[0].description })
    return thunkApi.rejectWithValue(error[0].description)
  }
})
