import {
    frontApp, backgroundApp
} from "./appPixi.js";
import {
    settings
} from "./settings.js";

const appWidth = frontApp.screen.width;
const appHeight = frontApp.screen.height;

const aspectRatio = appWidth / appHeight

const aspectRatioX = appWidth / settings.originalWidth
const aspectRatioY = appHeight / settings.originalHeight


export function spriteSettings(spriteName, textureName, containerName) {
    spriteName.texture = textureName
    containerName.addChild(spriteName)
    spriteName.anchor.set(0.5)
    return spriteName
};

export function animatedSpriteSettings(spriteName, frames, containerName) {
    spriteName = new PIXI.AnimatedSprite(frames)
    spriteName.textures = frames
    containerName.addChild(spriteName)
    spriteName.anchor.set(0.5)
    return spriteName
};

// Generate the random value between the 'min' and 'max' recieved
export function getRandomValue (min = 0, max = 5) {
  return Math.floor(Math.random() * (max - min) + min)
}

// Provide the scale multiplier for given source in respect of given source
export function getScale (sourceWidth, sourceHeight, refWidth, refHeight, drawableArea = 1) {

  let scale = 1
  const isWidthSmaller = sourceWidth < refWidth
  const isHeightSmaller = sourceHeight < refHeight

  if (isWidthSmaller && isHeightSmaller) {
    const vacantWidthSpace = 1 - (sourceWidth / refWidth)
    const vacantHeightSpace = 1 - (sourceHeight / sourceWidth)
    scale = 1 + Math.min(vacantWidthSpace, vacantHeightSpace)

  } else if (!isWidthSmaller && isHeightSmaller) {
    const redWidth = 1 - (refWidth / sourceWidth)
    scale = 1 - redWidth

  } else if (isWidthSmaller && !isHeightSmaller) {
    const redHeight = 1 - (refHeight / sourceHeight)
    scale = 1 - redHeight

  } else if (!isWidthSmaller && !isHeightSmaller) {
    const redWidth = 1 - (refWidth / sourceWidth)
    const redHeight = 1 - (refHeight / sourceHeight)
    scale = 1 - Math.max(redWidth, redHeight)

  }
  return scale * drawableArea
}

export function getWidthScale(sourceWidth, refWidth, drawableArea = 1) {
  const width = refWidth * drawableArea
  return sourceWidth / width
}
