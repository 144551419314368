import React, { useState, useEffect, useRef } from 'react'
import './Header.scss'
import * as yup from 'yup'
import Button from 'components/ui-kit/Button/index'
import { MaximizeFillIcon, MenuNavIcon, SettingsFillIcon, VolumeFillIcon, VolumeFillProfileIcon } from 'assets/icons/index'
// import SwitchButton from 'components/ui-kit/SwitchButton/index'
import Image from 'components/ui-kit/Image/index'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserInformation } from 'redux-store/thunk/user.thunk'
// import { getPrecision, numberWithCommas } from 'utils/common-functions/index'
import useGameSettingsUpdateSocketNameSpace from 'socket-resources/hooks/useGameSettingsUpdateSocketNameSpace'
import { getAllCurrency, getGameSetting } from 'redux-store/thunk/gameSettings.thunk'
// import { signOut } from 'utils/common-services/cookie.service'
import { setGameMusicVolume, setGameSoundVolume, updateGameSetting } from 'redux-store/redux/slices/gameSettings.slice'
import BetHistoryModal from 'pages/NewCrashGame/components/BetHistoryModal/index'
import ProvablyFair from 'pages/NewCrashGame/components/ProvablyFair/index'
import GameLimit from 'pages/NewCrashGame/components/GameLimit/index'
import { userLogin } from 'redux-store/thunk/auth.thunk'
import { DEFAULT_GAME_ID, DEFAULT_MUSIC_VOLUME, ROUTE_PATHS } from 'utils/constants/index'
import { useLocation } from '../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import CrashSettingsModal from 'pages/NewCrashGame/components/CrashSettingsModal/index'
import CrashSubSettingsModal from 'pages/NewCrashGame/components/CrashSubSettingsModale/index'
// import { setShowName } from 'redux-store/redux/slices/auth.slice'

const NewHeader = () => {
  const dispatch = useDispatch()
  const { search } = useLocation()
  const operatorId = new URLSearchParams(search).get('operator')
  const playerToken = new URLSearchParams(search).get('token')
  const currencyCode = new URLSearchParams(search).get('currency')
  const { userInfo, isLoggedIn } = useSelector((state) => state.auth)
  const { gameSoundVolume, systemGameData, gameMusicVolume } = useSelector((state) => state.gameSetting)
  const listenGameSettingsUpdate = useGameSettingsUpdateSocketNameSpace()
  const [isOpenHistoryModal, setIsOpenHistoryModal] = useState(false)
  const [isOpenProvablyFair, setIsOpenProvablyFair] = useState(false)
  const [isOpenGameLimits, setIsOpenGameLimits] = useState(false)
  const [isOpenSettingsModal, setIsOpenSettingsModal] = useState(false)
  const [isOpenSubSettingsModal, setIsOpenSubSettingsModal] = useState(false)
  const [currentOffset, setCurrentOffset] = useState(0)
  const [gameId, setGameId] = useState('')
  const [gameSetting, setGameSetting] = useState(null)

  // const displayName = useMemo(() => {
  //   return userDetails?.firstName || userDetails?.lastName || 'Player'
  // }, [userDetails])

  const buttonClickSoundRef = useRef(null)
  const crashGameMusicRef = useRef(null)

  const handleLoginUser = () => {
    const params = {
      payload: {
        operatorId,
        operatorUserToken: playerToken,
        currencyCode
      },
      history
    }
    dispatch(userLogin(params))
  }

  useEffect(() => {
    const gamePath = window.location.pathname
    let id = ''
    switch (gamePath) {
      case ROUTE_PATHS.crashGame:
        id = DEFAULT_GAME_ID.CRASH
        break
      default:
        id = ''
    }
    setGameId(id)
  }, [])

  useEffect(() => {
    if (playerToken && currencyCode && operatorId) {
      handleLoginUser()
    } else {
      history.push(ROUTE_PATHS.home)
    }
  }, [playerToken, currencyCode, operatorId])

  useEffect(() => {
    if (systemGameData && currencyCode && gameId) {
      const gamesSettings = systemGameData?.find(ele => ele.gameId === gameId)
      const maxBetData = gamesSettings.maxBet[currencyCode]
      const maxProfitData = gamesSettings.maxProfit[currencyCode]
      const minBetData = gamesSettings.minBet[currencyCode]
      setGameSetting({ maxBetData, maxProfitData, minBetData })
    }
  }, [currencyCode, systemGameData, gameId])

  useEffect(() => {
    crashGameMusicRef.current = new Audio()
    crashGameMusicRef.current.src = '/assets/music/airship-background-music.wav'
    crashGameMusicRef.current.volume = DEFAULT_MUSIC_VOLUME
    crashGameMusicRef.current.loop = true

    buttonClickSoundRef.current = new Audio()
    buttonClickSoundRef.current.src = '/assets/music/button-click.wav'

    dispatch(getAllCurrency())
    dispatch(getGameSetting())
    window.addEventListener('beforeunload', () => {
      // signOut()
    })

    const clearGameSettingsUpdate = listenGameSettingsUpdate(e => {
      // console.log(e.data)
      handleGameSettingsUpdate(e.data)
    })

    return () => {
      crashGameMusicRef.current?.pause()?.then(() => {})?.catch(() => {})
      buttonClickSoundRef.current?.pause()?.then(() => {})?.catch(() => {})
      clearGameSettingsUpdate()
    }
  }, [])

  const playClickSound = () => {
    try {
      if (gameSoundVolume && buttonClickSoundRef.current) {
        buttonClickSoundRef.current?.play()?.then(() => {})?.catch(() => {})
      }
    } catch (error) {

    }
  }

  const systemGameDataSchemas = yup.array().of(yup.object().shape({
    id: yup.string().required(),
    gameId: yup.number().required(),
    maxBet: yup.object().shape({
      USD: yup.string(),
      EUR: yup.string()
    }),
    minBet: yup.object().shape({
      USD: yup.string(),
      EUR: yup.string()
    }),
    maxProfit: yup.object().shape({
      USD: yup.string(),
      EUR: yup.string()
    }),
    houseEdge: yup.number().required(),
    minOdds: yup.number().required(),
    maxOdds: yup.number().required(),
    minAutoRate: yup.number().required(),
    createdAt: yup.string().required(),
    updatedAt: yup.string().required()
  })).min(1)

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchUserInformation())
    }
  }, [isLoggedIn])

  const handleGameSettingsUpdate = async (data) => {
    if (data) {
      const isValid = await systemGameDataSchemas.isValid(data)
      if (isValid) {
        dispatch(updateGameSetting(data))
      }
    }
  }

  // const handleOpen = () => {
  //   playClickSound()
  //   dispatch(setChooseCharacterOpen(true))
  // }

  // const handleBetHistoryOpen = () => {
  //   setCurrentOffset(0)
  //   dispatch(getMyBetsPaginated({
  //     limit: PAGINATION_LIMIT,
  //     offset: 0
  //   }))
  //   setIsOpenHistoryModal(true)
  // }
  // const closeDropdown = () => {
  //   const dropdownButton = document.getElementById('dropdownMenuButton1')
  //   if (dropdownButton) {
  //     dropdownButton.click() // Simulate a click on the dropdown button to close it
  //   }
  // }
  // const openExternalLink = (url) => {
  //   window.parent.location = url
  // }
  // const handleSwitch = () => {
  //   openErrorToaster({ message: 'Switch Currency in DDD Lobby!' })
  // }

  // const handleGameMusic = () => {
  //   if (musicRef?.current) {
  //     if (gameMusic) {
  //       !musicRef?.current?.paused && musicRef?.current?.pause()
  //     } else {
  //       musicRef?.current?.paused && musicRef?.current?.play()
  //     }
  //   }
  //   dispatch(setGameMusic())
  // }

  const handleGameMusicVolume = (volume = DEFAULT_MUSIC_VOLUME) => {
    try {
      if (crashGameMusicRef?.current) {
        crashGameMusicRef.current.volume = volume
        if (gameMusicVolume) {
          crashGameMusicRef.current?.play()?.then(() => {})?.catch(() => {})
        }
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    handleGameMusicVolume(gameMusicVolume)
  }, [gameMusicVolume])

  return (
    <>
      <header>
        <nav className='navbar navbar-expand navbar-dark py-0 mb-lg-20'>
          <div className='container custom-container'>
            <div className='header-flex-box d-flex w-100 align-items-center px-12 px-lg-20'>
              <div className='flex-1 d-none d-lg-block' />
              <div className='navbar-brand flex-1 text-lg-center'>
                <Image src='/assets/images/common/airship-logo.png' alt='logo' />
              </div>
              <div className='flex-1' id='navbarScroll'>
                <div className='d-flex justify-content-end'>
                  <ul className='navbar-nav my-2 my-lg-0 gap-0 align-items-center w-auto me-lg-3'>
                    <li className='nav-item ms-auto text-white me-2 nav-balance'>
                      <span className='fw-bold nav-balance-label'>Balance:</span> <span className='fw-bolder'>${(userInfo?.wallet?.balance || 0).toFixed(2)}</span>
                    </li>
                    <li className='nav-item ms-auto d-none d-md-block'>
                      <Button
                        type='button'
                        className='py-2 px-1'
                      >
                        <MaximizeFillIcon />
                      </Button>
                    </li>
                    <li className='nav-item ms-auto d-none d-md-block'>
                      <Button
                        type='button'
                        className='py-2 px-1'
                        onClick={() => setIsOpenSettingsModal(true)}
                      >
                        <SettingsFillIcon />
                      </Button>
                    </li>
                    <li className='nav-item ms-auto d-none d-md-block me-md-3'>
                      <Button
                        onClick={() => {
                          dispatch(setGameSoundVolume(gameSoundVolume === 0 ? 1 : 0))
                          dispatch(setGameMusicVolume(gameMusicVolume === 0 ? 1 : 0))
                        }}
                        type='button'
                        className='py-2 px-1'
                      >
                        {gameSoundVolume !== 0 || gameMusicVolume !== 0
                          ? (
                            <VolumeFillIcon />
                            )
                          : (
                            <VolumeFillProfileIcon />
                            )}
                      </Button>
                    </li>
                    <li className='nav-item ms-auto d-block d-md-none'>
                      <Button
                        type='button'
                        className='py-2 px-1'
                        onClick={() => setIsOpenSettingsModal(true)}
                      >
                        <MenuNavIcon />
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>

      <CrashSettingsModal
        gameSetting={gameSetting}
        setIsOpenSettingsModal={setIsOpenSettingsModal}
        isOpenSettingsModal={isOpenSettingsModal}
        handleGameMusicVolume={handleGameMusicVolume}
      />
      <CrashSubSettingsModal
        setIsOpenSubSettingsModal={setIsOpenSubSettingsModal}
        isOpenSubSettingsModal={isOpenSubSettingsModal}
      />
      <BetHistoryModal
        setIsOpenHistoryModal={setIsOpenHistoryModal}
        isOpenHistoryModal={isOpenHistoryModal}
        currentOffset={currentOffset}
        setCurrentOffset={setCurrentOffset}
      />
      <ProvablyFair
        setIsOpenProvablyFair={setIsOpenProvablyFair}
        isOpenProvablyFair={isOpenProvablyFair}
        playClickSound={playClickSound}
      />
      <GameLimit
        setIsOpenGameLimits={setIsOpenGameLimits}
        isOpenGameLimits={isOpenGameLimits}
      />
    </>
  )
}

export default NewHeader
