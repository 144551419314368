import React, { useState } from 'react'
import './CrashSettingsModal.scss'
import Popup from 'components/ui-kit/Popup/index'
import Button from 'components/ui-kit/Button/index'
import { ArrowFillUpIcon, CrossIcon, LeftFillArrowIcon, MusicNoteIcon, PlayFillIcon, UsersFillIcon, VolumeFillProfileIcon } from 'assets/icons/index'
import SwitchButton from 'components/ui-kit/SwitchButton/index'
import RangeSlider from 'components/ui-kit/RangeSlider/index'
import { setGameMusicVolume, setGameSoundVolume } from 'redux-store/redux/slices/gameSettings.slice'
import { useDispatch, useSelector } from 'react-redux'
import { formatTime, getPrecision } from 'utils/common-functions/index'
import { useTranslation } from 'react-i18next'
import { animationVisibility } from 'pixi-js-scripts/crash-game/gameManager'

const SUB_SETTINGS = {
  SETTINGS: 'Settings',
  LIMITS: 'Limits',
  RULES: 'Rules',
  MY_BETS: 'My Bets'
}

const ACCORDION_VALUES = {
  HOW_TO_PLAY: 'HOW_TO_PLAY',
  GAME_DETAILS: 'GAME_DETAILS',
  AUTOPLAY: 'AUTOPLAY',
  SETTINGS: 'SETTINGS',
  DISCONNECTION_POLICY: 'DISCONNECTION_POLICY',
  VERSION: 'VERSION'
}

const CrashSettingsModal = ({
  gameSetting,
  setIsOpenSettingsModal,
  isOpenSettingsModal,
  handleGameMusicVolume
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { myBetsData } = useSelector((state) => state.crashGame)
  const { gameSoundVolume, gameMusicVolume } = useSelector((state) => state.gameSetting)
  const [isAccordionToggle, setIsAccordionToggle] = useState(ACCORDION_VALUES.HOW_TO_PLAY)
  const [subSettingsToggle, setSubSettingsToggle] = useState(SUB_SETTINGS.SETTINGS)
  const [isAnimation, setIsAnimation] = useState(true)
  return (
    <>
      <Popup className='crash-settings-modal' isOpenModal={isOpenSettingsModal}>
        <Button className='btn-close-modal p-0' onClick={() => setIsOpenSettingsModal(false)}>
          <div>
            <CrossIcon />
          </div>
        </Button>
        <div className='modal-head d-flex align-items-center'>
          {subSettingsToggle === SUB_SETTINGS.SETTINGS
            ? <UsersFillIcon className='m-1 users-head-icon' />
            : <LeftFillArrowIcon
                className='m-1 users-head-icon cursor-pointer'
                onClick={() => setSubSettingsToggle(SUB_SETTINGS.SETTINGS)}
              />}
          {subSettingsToggle}
        </div>
        <div className='content-body'>
          {
          subSettingsToggle === SUB_SETTINGS.SETTINGS && (
            <>
              <div className='users-edit d-flex align-items-center p-2 mb-20'>
                <div className='flex-grow-1'>
                  <div className='fw-bold'>Nickname</div>
                  <div className='fw-bold'>Recruit</div>
                </div>
                {/* <div className='flex-shrink-0'>
                  <Button
                    className='profile-edit py-0 px-1'
                  >
                    <ProfileEditIcon />
                  </Button>
                </div> */}
              </div>
              <div className='profile-buttons-sectoion'>
                <Button
                  className='btn-warning w-100'
                  onClick={() => setSubSettingsToggle(SUB_SETTINGS.LIMITS)}
                >
                  Limits
                </Button>
                <Button
                  className='btn-warning w-100'
                  onClick={() => setSubSettingsToggle(SUB_SETTINGS.RULES)}
                >
                  Rules
                </Button>
                <Button
                  className='btn-warning w-100'
                  onClick={() => setSubSettingsToggle(SUB_SETTINGS.MY_BETS)}
                >
                  My Bets
                </Button>
                {/* <Button className='btn-warning w-100'
              onClick={()=> setSubSettingsToggle('Free_bets')}
            >
              Free Bets
            </Button> */}
              </div>
              <div className='game-settings-section'>
                <ul className='game-settings-list list-unstyled mb-0'>
                  <li className='d-flex align-items-center mb-1'><PlayFillIcon className='me-3' />
                    <div className='game-animation-setting text-white fw-bolder d-flex justify-content-between w-100 align-items-center'>
                      Animation
                      <SwitchButton
                        checked={isAnimation}
                        onChange={(e) => {
                          animationVisibility(e.target.checked)
                          setIsAnimation(e.target.checked)
                        }}
                        className='custom-switch secondary-switch'
                      />
                    </div>
                  </li>
                  <li className='d-flex align-items-center mb-1'>
                    <VolumeFillProfileIcon className='me-3' />
                    <div className='game-volume-setting w-100'>
                      <RangeSlider
                        min='0'
                        max='100'
                        value={gameSoundVolume * 100}
                        onChange={(e) => {
                          dispatch(setGameSoundVolume((+e.target.value / 100)))
                        }}
                        secondaryBgColor='var(--brown-900)'
                      />
                    </div>
                  </li>
                  <li className='d-flex align-items-center'>
                    <MusicNoteIcon className='me-3' />
                    <div className='game-music-setting w-100'>
                      <RangeSlider
                        min='0'
                        max='100'
                        value={gameMusicVolume * 100}
                        onChange={(e) => {
                          dispatch(setGameMusicVolume(+e.target.value / 100))
                        }}
                        secondaryBgColor='var(--brown-900)'
                      />
                    </div>
                  </li>
                </ul>
              </div>
            </>
          )
}
          <div className='game-settings-section'>
            {subSettingsToggle === SUB_SETTINGS.LIMITS && (
              <>
                {/* Limits content section  */}
                <div className='limit-settings pb-3'>
                  <div className='users-edit p-2 mb-2'>
                    <div className='fw-bold'>Min bet</div>
                    <div className='fw-bold text-white fs-12'>$ {gameSetting?.minBetData}</div>
                  </div>
                  <div className='users-edit p-2 mb-2'>
                    <div className='fw-bold'>Max bet</div>
                    <div className='fw-bold text-white fs-12'>$ {gameSetting?.maxBetData}</div>
                  </div>
                  <div className='users-edit p-2 mb-2'>
                    <div className='fw-bold'>Max profit</div>
                    <div className='fw-bold text-white fs-12'>$ {gameSetting?.maxProfitData}</div>
                  </div>
                </div>
              </>
            )}
            {subSettingsToggle === SUB_SETTINGS.RULES && (
              <>
                {/* Rules content section  */}
                <div className='rules-settings pb-3'>
                  {/* items */}
                  <div className={`accordion mb-2 ${isAccordionToggle === ACCORDION_VALUES.HOW_TO_PLAY ? 'active' : ''}`}>
                    <div className='d-flex justify-content-between align-items-center p-2 text-white'>
                      <div className='fw-bold accordion-title'>How to play?</div>
                      <ArrowFillUpIcon className='accordion-arrow cursor-pointer' id='accordion_play' onClick={() => setIsAccordionToggle(ACCORDION_VALUES.HOW_TO_PLAY)} />
                    </div>
                    <div className='accordion-content'>
                      <div className='p-2'>
                        <ul className='fw-bold text-white ps-4'>
                          <li className='mb-2'>Place your bet before the plane takes off</li>
                          <li className='mb-2'>Take your chance and wait until multiplier reach your desired goal</li>
                          <li className='mb-2'>Cash out before the plane flies away!</li>
                        </ul>
                        <p className='fw-bold text-white mb-0'>
                          The longer you stay in the game, the higher payoff you will get. Please be noted that the plane may fly away at any moment. If you fail to cash out on time, you will lose the bet.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* items */}
                  <div className={`accordion mb-2 ${isAccordionToggle === ACCORDION_VALUES.GAME_DETAILS ? 'active' : ''}`}>
                    <div className='d-flex justify-content-between align-items-center p-2 text-white'>
                      <div className='fw-bold accordion-title'>Game details</div>
                      <ArrowFillUpIcon className='accordion-arrow cursor-pointer' id='accordion_game_details' onClick={() => setIsAccordionToggle(ACCORDION_VALUES.GAME_DETAILS)} />
                    </div>
                    <div className='accordion-content'>
                      <div className='p-2'>
                        <p className='fw-bold text-white mb-0'>
                          Game details
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* items */}
                  <div className={`accordion mb-2 ${isAccordionToggle === ACCORDION_VALUES.AUTOPLAY ? 'active' : ''}`}>
                    <div className='d-flex justify-content-between align-items-center p-2 text-white'>
                      <div className='fw-bold accordion-title'>Auotplay</div>
                      <ArrowFillUpIcon className='accordion-arrow cursor-pointer' id='accordion_autoplay' onClick={() => setIsAccordionToggle(ACCORDION_VALUES.AUTOPLAY)} />
                    </div>
                    <div className='accordion-content'>
                      <div className='p-2'>
                        <p className='fw-bold text-white mb-0'>
                          Autoplay
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* items */}
                  <div className={`accordion mb-2 ${isAccordionToggle === ACCORDION_VALUES.SETTINGS ? 'active' : ''}`}>
                    <div className='d-flex justify-content-between align-items-center p-2 text-white'>
                      <div className='fw-bold accordion-title'>Settings</div>
                      <ArrowFillUpIcon className='accordion-arrow cursor-pointer' id='accordion_settings' onClick={() => setIsAccordionToggle(ACCORDION_VALUES.SETTINGS)} />
                    </div>
                    <div className='accordion-content'>
                      <div className='p-2'>
                        <p className='fw-bold text-white mb-0'>
                          Settings
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className={`accordion mb-2 ${isAccordionToggle === ACCORDION_VALUES.DISCONNECTION_POLICY ? 'active' : ''}`}>
                    <div className='d-flex justify-content-between align-items-center p-2 text-white'>
                      <div className='fw-bold accordion-title'>Disconnection Policy</div>
                      <ArrowFillUpIcon className='accordion-arrow cursor-pointer' id='accordion_disconnection' onClick={() => setIsAccordionToggle(ACCORDION_VALUES.DISCONNECTION_POLICY)} />
                    </div>
                    <div className='accordion-content'>
                      <div className='p-2'>
                        <p className='fw-bold text-white mb-0'>
                          Disconnection Policy
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className={`accordion mb-2 ${isAccordionToggle === ACCORDION_VALUES.VERSION ? 'active' : ''}`}>
                    <div className='d-flex justify-content-between align-items-center p-2 text-white'>
                      <div className='fw-bold accordion-title'>Version</div>
                      <ArrowFillUpIcon className='accordion-arrow cursor-pointer' id='accordion_version' onClick={() => setIsAccordionToggle(ACCORDION_VALUES.VERSION)} />
                    </div>
                    <div className='accordion-content'>
                      <div className='p-2'>
                        <p className='fw-bold text-white mb-0'>
                          Version
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {subSettingsToggle === SUB_SETTINGS.MY_BETS &&
            (
              <>
                {/* my-bet content section  */}
                <table className='table text-white fs-12 table-borderless mb-0 fw-bold'>
                  <thead>
                    <tr>
                      <th>Time</th>
                      <th>Multiple</th>
                      <th className='text-end'>Payout</th>
                    </tr>
                  </thead>
                </table>
                <div className='my-bet-settings mb-3'>
                  <table className='table text-white fs-12 table-borderless mb-0 fw-bold'>
                    <tbody>
                      {
                        myBetsData?.rows?.length
                          ? (
                              myBetsData?.rows?.map(data => {
                                const escapeRate = getPrecision(data?.escapeRate)
                                const winningAmount = getPrecision(data?.winningAmount)
                                return (
                                  <tr key={data?.id.toString()}>
                                    <td>
                                      {formatTime(data?.createdAt)}
                                    </td>
                                    <td className='text-green-900'>{escapeRate === 0 ? '--' : `${escapeRate}x`}</td>
                                    <td className='text-green-600 text-end'>{winningAmount > 0 ? '+' : ''}${winningAmount.toFixed(2)}</td>
                                  </tr>
                                )
                              }
                              )
                            )
                          : (
                            <tr className='text-center'>
                              <td colSpan='6'>
                                {(t('noData'))}
                              </td>
                            </tr>
                            )
                      }
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </div>
        </div>
      </Popup>
    </>
  )
}
export default CrashSettingsModal
