const basePath = '/assets/pixi-assets/plinko/'

export const path = {
  imagePath: basePath,
  spriteSheetsPath: basePath + 'spriteSheets/'
}

export const settings = {
  originalWidth: 1900,
  originalHeight: 1900,
  backgroundColor: 0x000000,
  backgroundAlpha: 0,
  div: () => document.getElementById('pixi-plinko-game'),
  lines: {
    initialNumber: 8
  },
  points: {
    shine: {
      animationSpeed: 1.3
    }
  },
  box: {
    boxSpeed: 2,
    height: 50,
    width: 50,
    fontSizeInitial: 50
  },
  point: {
    initialRadius: 16
  },
  matter: {
    gravity: 1// 3
  }
}
// ----------------------8----9----10------11-----12---13-----14-------15 -- 16
export const xForce = [2.34, 2, 1.95, 1.81, 1.7, 1.63, 1.588, 1.5, 1.45]
export const yForce = [6.45, 6.34, 6, 5.5, 5.45, 5.38, 5.34, 5.3, 5.15]
