const basePath = '/assets/pixi-assets/crash-game/'

export const path = {
  imagePath: basePath + "images/",
  spriteSheetsPath: basePath + "spriteSheets/",
  fontPath: basePath + "fonts/",
}

const axisGapValue = 75

export const settings = {
  originalWidth: 1920,
  originalHeight: 1080,
  axisGap: axisGapValue,
  xCorrectedPos: axisGapValue * 1.8,
  yCorrectedPos: axisGapValue * 2.2, // 75

  totalDivisions: 10,
  graphSpeed: 20,

  assetsProgress: {
    bar: {
      backgroundColor: '#4cd137',
      border: 4,
      borderColor: '#FFFFFF',
      fillColor: '#e55039',
      height: 30,
      radius: 25,
      width: 550,
    },
    text: {
      visible: true,
      fill: 0xffffff,
      fontSize: 30,
    }
  },

  startGameProgress: {
    bar: {
      backgroundColor: '#220A06',
      border: 2,
      borderColor: '#9d4c36',
      fillColor: '#FFB823',
      height: 11,
      radius: 0,
      width: 285,
    },
    text: {
      visible: false,
      fill: 0xffffff,
      fontSize: 30,
    }
  }
}
