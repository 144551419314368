import { createSlice } from '@reduxjs/toolkit'
import { DEFAULT_PAGE_CALLS } from 'pages/RouletteGame/roulette.constants'
import { getMyBetsRoulette, placedBetRouletteGame } from './rouletteGame.thunk'
import { getWinningBetTypes } from './game-helpers/index'

const initialState = {
  placedBetData: null,
  betLock: false,
  betLoading: false,
  myBetsData: {
    count: 0,
    rows: []
  },
  myBetsLoading: false,
  showWin: false,
  recentWinning: [],
  winTypesContent: null
}

const {
  actions: {
    setBetLock,
    setRouletteRoundEnd,
    appendMyBetsRoulette
  },
  reducer
} = createSlice({
  name: 'rouletteGame',
  initialState,
  reducers: {
    setBetLock: (state, action) => {
      return {
        ...state,
        betLock: false
      }
    },
    setRouletteRoundEnd: (state, action) => {
      return {
        ...state,
        showWin: true,
        betLock: false,
        recentWinning: [state.placedBetData, ...state.recentWinning].slice(0, 4)
      }
    },
    appendMyBetsRoulette: (state, action) => {
      const { nextServerSeedHash, ...data } = state.placedBetData || { nextServerSeedHash: '', data: {} }
      if (nextServerSeedHash) {
        const myBetsDataRows = [data, ...state.myBetsData?.rows]
        if (myBetsDataRows.length > DEFAULT_PAGE_CALLS) {
          myBetsDataRows.pop()
        }
        return {
          ...state,
          myBetsData: {
            count: state.myBetsData?.count + 1,
            rows: myBetsDataRows
          }
        }
      }
      return state
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(placedBetRouletteGame.pending, (state, action) => {
        return {
          ...state,
          showWin: false,
          betLock: true,
          betLoading: true,
          winTypesContent: null
        }
      })
      .addCase(placedBetRouletteGame.fulfilled, (state, action) => {
        const { winningNumber } = action.payload || { winningNumber: 0 }

        const winTypesContent = getWinningBetTypes(winningNumber)

        return {
          ...state,
          winTypesContent,
          placedBetData: action.payload,
          betLoading: false
        }
      })
      .addCase(placedBetRouletteGame.rejected, (state, action) => {
        return {
          ...state,
          betLock: false,
          betLoading: false
        }
      })
      .addCase(getMyBetsRoulette.pending, (state, action) => {
        return {
          ...state,
          myBetsData: action.payload,
          myBetsLoading: true

        }
      })
      .addCase(getMyBetsRoulette.fulfilled, (state, action) => {
        return {
          ...state,
          myBetsData: action.payload,
          myBetsLoading: false

        }
      })
      .addCase(getMyBetsRoulette.rejected, (state, action) => {
        return {
          ...state,
          myBetsLoading: false

        }
      })
  }
})

export {
  setBetLock,
  setRouletteRoundEnd,
  appendMyBetsRoulette
}

export default reducer
