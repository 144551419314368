import * as corePixiInit from './core/initializePixi.js'
import { playBGMSound, playLeftDropSound, playOrangeBoxSound, playRedBoxSound, playRightDropSound, playYellowBoxSound } from './soundManager.js'
import { appendMyBetsPlinko, setBetLock } from 'redux-store/redux/slices/plinkoGame.slice.js'
import store from 'redux-store/store.js'
import { updateCreditWalletWaitQueue } from 'redux-store/redux/slices/auth.slice.js'

export const pixiApplicationInit = (setIsPixiAssetsLoaded) => corePixiInit.init(setIsPixiAssetsLoaded)

export const pixiAssetsLoad = () => corePixiInit.loadPixiAssets()
console.log(window)
const loadAndExecuteFunction = (func) => (...args) =>
  corePixiInit.loadGameUIFunctions()
    .then((gameUI) => gameUI[func](...args))
    .catch((error) => {
      console.error(`Error using gameUI.${func}():`, error)
      throw error
    })

export const simulateBallCreation = (dataForBall, winningSlot, dropDetails) => loadAndExecuteFunction('simulateBallCreation')(dataForBall, winningSlot, dropDetails)
export const changeLinesNumber = (value, payoutText) => loadAndExecuteFunction('changeLinesNumber')(value, payoutText)
export const setupPayoutTextPixi = (value) => loadAndExecuteFunction('setupPayoutText')(value)
export const destroyAllBalls = loadAndExecuteFunction('destroyAllBalls')
export const destroyAllPoints = loadAndExecuteFunction('destroyAllPoints')
export const destroyAllBoxes = loadAndExecuteFunction('destroyAllBoxes')
export const destroyMatterWorld = loadAndExecuteFunction('destroyMatterWorld')

export const pixiApplicationDestroy = () => {
  destroyAllBalls()
    .then(() => destroyAllBoxes())
    .then(() => destroyAllPoints())
    .then(() => destroyMatterWorld())
    .then(() => corePixiInit.destroyPixiApp())
    .catch((error) => {
      // Handle errors if any
      console.error('Error during destroyAllBallsPromise:', error)
    })
}

// Sounds
export const playBGM = () => {
  // Background Music
  playBGMSound()
}
export const playRightDrop = () => {
  playRightDropSound()
}
export const playLeftDrop = () => {
  playLeftDropSound()
}
export const playYellowBox = () => {
  playYellowBoxSound()
}
export const playOrangeBox = () => {
  playOrangeBoxSound()
}
export const playRedBox = () => {
  playRedBoxSound()
}

export const dispatching = () => {
  // Comment this line in standalone mode
  dispatchRoundEnd()
  // console.log("dispatch2")
  store.dispatch(setBetLock())
}

export const dispatchRoundEnd = () => {
  store.dispatch(updateCreditWalletWaitQueue())
}

export const dispatchEveryBallTouch = () =>  {
  store.dispatch(appendMyBetsPlinko())

}
// document
//     .getElementById("assetsLoadButton")
//     .addEventListener("click", () => pixiAssetsLoad());

// document
//     .getElementById("pixiLoadButton")
//     .addEventListener("click", () => pixiApplicationInit());

// document
//     .getElementById("backButton")
//     .addEventListener("click", () => pixiApplicationDestroy());
