// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input.custom-switch[type="checkbox"] {
  position: relative;
  width: 36px;
  height: 20px;
  appearance: none;
  -webkit-appearance: none;
  background: var(--minecashoutbtn);
  outline: none;
  border-radius: 20px;
  box-shadow: 0px 1px 2px 1px rgba(12, 77, 1, 0.2) inset;
  border: 1px solid var(--white);
  transition: 0.7s; }
  input.custom-switch[type="checkbox"]:before {
    content: '';
    position: absolute;
    left: 2px;
    box-shadow: 0 1px 0 var(--black-5);
    transform: scale(1.1);
    transition: .5s;
    cursor: pointer; }

input.custom-switch:checked[type="checkbox"] {
  border: 1px solid var(--green-600);
  background: var(--green-900); }
  input.custom-switch:checked[type="checkbox"]:before {
    left: 16px; }

input.custom-switch.secondary-switch[type="checkbox"]:before {
  width: 16px;
  height: 16px;
  border-radius: 50em;
  top: 1px;
  background: var(--white); }

input.custom-switch.secondary-switch:checked[type="checkbox"] {
  box-shadow: inset 0 0 5px var(--green-900); }
  input.custom-switch.secondary-switch:checked[type="checkbox"]:before {
    background: var(--white);
    box-shadow: 0 1px 0 var(--black-5); }
`, "",{"version":3,"sources":["webpack://./src/components/ui-kit/SwitchButton/SwitchButton.scss"],"names":[],"mappings":"AAAA;EAGM,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,wBAAwB;EACxB,iCAAiC;EACjC,aAAa;EACb,mBAAmB;EACnB,sDAAuD;EACvD,8BAA8B;EAC9B,gBAAgB,EAAA;EAbtB;IAgBQ,WAAW;IACX,kBAAkB;IAClB,SAAS;IACT,kCAAkC;IAClC,qBAAqB;IACrB,eAAe;IACf,eAAe,EAAA;;AAtBvB;EA2BM,kCAAkC;EAClC,4BAA4B,EAAA;EA5BlC;IA+BQ,UAAU,EAAA;;AA/BlB;EAsCU,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,QAAQ;EACR,wBAAwB,EAAA;;AA1ClC;EA+CQ,0CAA0C,EAAA;EA/ClD;IAiDU,wBAAwB;IACxB,kCAAkC,EAAA","sourcesContent":["input {\n  &.custom-switch {\n    &[type=\"checkbox\"] {\n      position: relative;\n      width: 36px;\n      height: 20px;\n      appearance: none;\n      -webkit-appearance: none;\n      background: var(--minecashoutbtn);\n      outline: none;\n      border-radius: 20px;\n      box-shadow: 0px 1px 2px 1px rgba(12, 77, 1, 0.20) inset;\n      border: 1px solid var(--white);\n      transition: 0.7s;\n\n      &:before {\n        content: '';\n        position: absolute;\n        left: 2px;\n        box-shadow: 0 1px 0 var(--black-5);\n        transform: scale(1.1);\n        transition: .5s;\n        cursor: pointer;\n      }\n    }\n\n    &:checked[type=\"checkbox\"] {\n      border: 1px solid var(--green-600);\n      background: var(--green-900);\n\n      &:before {\n        left: 16px;\n      }\n    }\n\n    &.secondary-switch {\n      &[type=\"checkbox\"] {\n        &:before {\n          width: 16px;\n          height: 16px;\n          border-radius: 50em;\n          top: 1px;\n          background: var(--white);\n        }\n      }\n\n      &:checked[type=\"checkbox\"] {\n        box-shadow: inset 0 0 5px var(--green-900);\n        &:before {\n          background: var(--white);\n          box-shadow: 0 1px 0 var(--black-5);\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
