import {
    settings
} from "./settings.js";
import * as PIXI from "pixi.js"

export const backgroundApp = new PIXI.Application({
    width: settings.originalWidth,
    height: settings.originalHeight,
    antialias: true,
    background: '#342DB1',
    //backgroundAlpha: 0,
    // resolution: window.devicePixelRatio,
});

// document.getElementById('backgroundDiv').appendChild(backgroundApp.view)

export const frontApp = new PIXI.Application({
    // width: settings.originalWidth,
    // height: settings.originalHeight,
    // backgroundColor: 0x1E0A2A,
    backgroundAlpha: 0,
    antialias: true,
    // resolution: window.devicePixelRatio,
});
// document.getElementById("frontDiv").appendChild(frontApp.view);

function appTransform(x, y) {
    frontApp.renderer.resize(x, y);
    backgroundApp.renderer.resize(x + 100, y + 100)
}
globalThis.__PIXI_APP__ = frontApp;
// appTransform(800, 450)

// resize();

export function resize() {
  const pixiControlDiv = document.getElementsByClassName('pixi-control-section')
  const headerHeight = document.getElementsByClassName('header-flex-box')
  const gameContainer = document.getElementById('pixi-game-section')
  let gameHeight = 0
  const gameWidth = window.innerWidth

  if(window.matchMedia("(orientation: portrait)").matches) {
    gameHeight = window.innerHeight - pixiControlDiv[0].clientHeight - headerHeight[0].clientHeight - 110;
   // frontApp.renderer.resize(gameWidth, gameHeight)


  } else {
    gameHeight = window.innerHeight - pixiControlDiv[0].clientHeight - 30;
   // frontApp.renderer.resize(gameContainer.offsetWidth, gameContainer.offsetHeight)
  }

  frontApp.renderer.resize(gameContainer.offsetWidth, gameContainer.offsetHeight)

  let scale = gameContainer.offsetWidth > gameContainer.offsetHeight ? gameContainer.offsetHeight / gameContainer.offsetWidth : gameContainer.offsetWidth / gameContainer.offsetHeight
  return scale
}
