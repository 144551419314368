const basePath = '/assets/pixi-assets/roulette-game/'

export const path = {
  imagePath: basePath + 'images/',
  spriteSheetsPath: basePath + 'spritesheets/'
}

export const settings = {
  originalWidth: 1920,
  originalHeight: 1080,

  rouletteWheelSpeed: 0.7,
  rouletteBallSpeed: 0.7,

  color: {
    red: 0xFF013C,
    black: 0x0F181F,
    neutral: 0x419E3F
  },

  padding: 2,

  normalButtonSize: { //  Button 1 - 36
    width: 50,
    height: 50
  },

  proximaNovaLight: {
    fontFamily: ['Helvetica', 'Arial'],
    fontSize: 100,
    fontWeight: 'bold',
    fill: '#FFFFFF',
    strokeThickness: 0,
    wordWrap: true,
    wordWrapWidth: 440
  }

}
