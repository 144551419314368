import { createSlice } from '@reduxjs/toolkit'
import { gameClose, userLogin } from 'redux-store/thunk/auth.thunk'
import { fetchUserInformation } from 'redux-store/thunk/user.thunk'
import { getPayloadFromToken } from 'utils/common-functions/index'

const defaultAuthState = {
  creditWalletWaitQueue: [],
  userInfo: null,
  userDetails: null,
  currencyId: null,
  currencyCode: null,
  isLoggedIn: false,
  showName: false,
  userInfoLoading: false
}

const authSlice = createSlice({
  name: 'authSlice',
  initialState: defaultAuthState,
  reducers: {
    appendCreditWalletWaitQueue: (state, action) => {
      return {
        ...state,
        creditWalletWaitQueue: [action.payload, ...state.creditWalletWaitQueue]
      }
    },
    updateCreditWalletWaitQueue: (state, action) => {
      let _creditWalletWaitQueue = [...state.creditWalletWaitQueue]
      const _userInfoWallet = _creditWalletWaitQueue[_creditWalletWaitQueue.length - 1] ?? state.userInfo.wallet
      _creditWalletWaitQueue = _creditWalletWaitQueue?.slice(0, _creditWalletWaitQueue.length - 1)
      return {
        ...state,
        creditWalletWaitQueue: _creditWalletWaitQueue,
        userInfo: {
          ...state.userInfo,
          wallet: _userInfoWallet
        }
      }
    },
    setShowName: (state, action) => {
      return {
        ...state,
        showName: !state.showName
      }
    },
    setUserInfo: (state, action) => ({
      ...state,
      userInfo: {
        ...state.userInfo,
        wallet: action.payload
      }
    })
  },
  extraReducers: (builder) => {
    builder
      .addCase(userLogin.pending, (state, action) => {
        return {
          ...state,
          userInfoLoading: true
        }
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        const { user, wallet, accessToken } = action.payload
        const payload = getPayloadFromToken(accessToken)
        const currencyId = payload?.currencyId
        const currencyCode = payload?.currencyCode
        return {
          ...state,
          currencyId,
          currencyCode,
          userInfo: { user, wallet },
          isLoggedIn: true,
          userInfoLoading: false
        }
      })
      .addCase(userLogin.rejected, (state, action) => {
        return {
          ...state,
          userInfoLoading: false
        }
      })
      .addCase(fetchUserInformation.fulfilled, (state, action) => {
        return {
          ...state,
          userDetails: action.payload
        }
      })
      .addCase(gameClose.fulfilled, (state, action) => {
        return defaultAuthState
      })
  }
})

export const { setUserInfo, appendCreditWalletWaitQueue, updateCreditWalletWaitQueue, setShowName } = authSlice.actions

export default authSlice.reducer
