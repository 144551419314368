import {
  frontApp,
  backgroundApp,
  resize
} from "./appPixi.js"
import {
  getScale,
  getWidthScale,
  spriteSettings
} from "./utility.js"
import {
  promiseAssets
} from "./assetsLoad.js"
import {
  maxHeight,
  maxWidth,
  rocketGapCalculate,
  setValuesToInitial
} from "./gameLogic.js"
import * as PIXI from "pixi.js"
import {
  startGameProgressBar
} from "./CommonProgressBar.js"
import {
  settings
} from "./settings.js"
import {
  random
} from "lodash"


let uiCreated = false

let appWidth = frontApp.screen.width
let appHeight = frontApp.screen.height

let appAspectRatio = frontApp.screen.width / frontApp.screen.height

const appAspectRatioX = appWidth / 1920
const appAspectRatioY = appHeight / 1080

// Which element covers how much area of the screen (as per figma)
const scalingPercentage = {
  desktop: {
    mainPlane: 19.30,

  },
  mobile: {
    mainPlane: 49.72,

  }

}

let xGraphic;
let yGraphic;

const containers = {
  rootContainer: new PIXI.Container(),
  dummyPlanesContainer: new PIXI.Container(),
  bgContainer: new PIXI.Container(),
  cloudContainer: new PIXI.Container(),
  loadingContainer: new PIXI.Container(),
  graphContainer: new PIXI.Container(),
  rocketContainer: new PIXI.Container(),
  axisContainer: new PIXI.Container(),
  centerTextContainer: new PIXI.Container(),

  //BgApp
  BgRootContainer: new PIXI.Container(),
  BgContainer: new PIXI.Container(),
  cloudContainer: new PIXI.Container(),
}

const gameAssets = {
  //GameBg
  cloud1: new PIXI.Sprite(),
  cloud2: new PIXI.Sprite(),

  //Center Text
  centerBigText: new PIXI.Text(),
  centerSmallText: new PIXI.Text(),
  crashCenterText: new PIXI.Text(),
  cashedoutText1: new PIXI.Text(),
  cashedoutText2: new PIXI.Text(),
  cashedoutText3: new PIXI.Text(),

  //rocket
  // rocketTextures: [],
  rocket: new PIXI.Sprite(),

  nearestPlane: null,
  farthestPlane: null,

  // rocket explosion
  explosionAnim: null,

  //loadingScreen
  countdownText: '',
  loadingText: '',
  loadingBar1: new PIXI.Sprite(),
  loadingBar2: new PIXI.Sprite(),



  //Axis
  axisDots: {
    x: [],
    y: [],
  },

  //Graph
  topLineGraph: new PIXI.Graphics(),
  bottomLineGraph: new PIXI.Graphics(),
  bottomGradient: new PIXI.Sprite(),

  //Fonts
  fonts: {
    bold: {},
    semiBold: {},
    ultraRegular: {}
  },

  background: new PIXI.Sprite(),

  clouds: []

}

function createUI() {
  if (!uiCreated) {
    appAspectRatio = resize()
    appWidth = frontApp.screen.width
    appHeight = frontApp.screen.height
    setupContainers()
    setupGameAssets()
    uiCreated = true
  }

  setValuesToInitial()
}

function setupContainers() {
  frontApp.stage.addChild(containers.rootContainer);

  containers.rootContainer.addChild(
    containers.bgContainer,
    containers.axisContainer,
    containers.graphContainer,
    containers.rocketContainer,
    containers.dummyPlanesContainer,
    containers.centerTextContainer,
    containers.loadingContainer,

  )

  //BgApp
  backgroundApp.stage.addChild(containers.BgRootContainer)
  containers.BgRootContainer.addChild(containers.BgContainer)
  containers.BgRootContainer.addChild(containers.cloudContainer)
  containers.BgRootContainer.position.set(backgroundApp.screen.width / 2, backgroundApp.screen.height / 2)

}

function setupGameAssets() {

  createBackgroundd()
  createClouds()

  setUpFonts()
  // createBackground();
  createLoadingScreen();
  createRocket();
  createCenterText();
  createGraph();
  createDummyRockets();
  createCashoutPopup();
  // createAxis();
}

function createBackgroundd() {

  gameAssets.background = spriteSettings(gameAssets.background, PIXI.Assets.get('Bg'), containers.BgContainer)

  if (window.matchMedia("(orientation: portrait)").matches) {
    gameAssets.background.height = backgroundApp.screen.height * 2
    gameAssets.background.width = gameAssets.background.height * 9
  } else {
    gameAssets.background.width = backgroundApp.screen.width * 3
    gameAssets.background.height = backgroundApp.screen.height * 3
  }
  gameAssets.background.position.x = gameAssets.background.width * 0.5 - backgroundApp.screen.width * 0.5
  gameAssets.background.position.y = backgroundApp.screen.height * 0.5 - gameAssets.background.height * 0.5
}

function sortAnimation(unsortedArray) {
  unsortedArray.sort((a, b) => {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  });

  unsortedArray.sort((a, b) => {
    if (a.length < b.length) {
      return -1;
    }
    if (a.length > b.length) {
      return 1;
    }
    return 0;
  });

  return unsortedArray;
}

function createClouds() {


  let variab = sortAnimation(Object.keys(promiseAssets.clouds.data.frames));
  for (let i of variab) {
    const cloud = {
      sprite: new PIXI.Sprite(promiseAssets.clouds.textures[i]),
      speed: 1 + Math.random() * 5
    }

    containers.cloudContainer.addChild(cloud.sprite)

    // console.log(backgroundApp.view.width+"||"+backgroundApp.view.height)

    if (window.innerWidth < window.innerHeight) {
      // console.log("mobile")
      cloud.sprite.scale.set(cloud.sprite.scale.x * 5, cloud.sprite.scale.y)
    }

    cloud.sprite.position.y = -100 + Math.random() * 400
    gameAssets.clouds.push(cloud);
  }

  // console.log(gameAssets.clouds)


  // for (let index = 0; index < 4; index++) {
  //   const cloud = {
  //     sprite: new PIXI.Sprite(),
  //     speed: null
  // }
  // cloud.sprite = spriteSettings(cloud.sprite, promiseAssets.cloud[`cloud${index}`], containers.cloudsContainer)
  // cloud.sprite.anchor.set(0.5)
  // //cloud.sprite.position.set(-app.view.width / 2 + (Math.random() * (app.view.width)), -app.view.height - (Math.random() * -app.view.height * 0.3))
  // //cloud.sprite.scale.set((Math.random() * (0.6 - 0.2) + 0.2) * appAspectRatioX)
  // cloud.speed = (0.2 + Math.random()) * appAspectRatioX
  // gameAssets.clouds.push(cloud)
  // }
}

function setUpFonts() {
  gameAssets.fonts.bold.fontFamily = promiseAssets.bold.family
  gameAssets.fonts.semiBold.fontFamily = promiseAssets.semiBold.family
  gameAssets.fonts.ultraRegular.fontFamily = promiseAssets.ultraRegular.family
}

function createBackground() {
  gameAssets.cloud1 = spriteSettings(gameAssets.cloud1, PIXI.Assets.get("allAssets").textures.cloud, containers.bgContainer)
  gameAssets.cloud1.position.set(appWidth * 0.85, appHeight * 0.85)

  gameAssets.cloud2 = spriteSettings(gameAssets.cloud2, PIXI.Assets.get("allAssets").textures.cloud, containers.bgContainer)
  gameAssets.cloud2.position.set(appWidth * 0.1, appHeight * 0.85)
}

let gameContainer;

//getAspectratio()

function getAspectratio(){
   gameContainer = document.getElementById('pixi-game-section')
  return  gameContainer.offsetWidth  /1920
}


function createLoadingScreen() {
  gameAssets.loadingText = new PIXI.Text('WAITING FOR NEXT ROUND', new PIXI.TextStyle({
    fontFamily: gameAssets.fonts.ultraRegular.fontFamily,
    fill: ['#8E092E'],
    fontSize: 100,
    align: 'center',
  }))
  gameAssets.loadingText.anchor.set(0.5)
  containers.loadingContainer.addChild(gameAssets.loadingText)

  gameAssets.countdownText = new PIXI.Text('5', new PIXI.TextStyle({
    fontFamily: gameAssets.fonts.ultraRegular.fontFamily,
    fill: ['#8E092E'],
    fontSize: 80
  }))
  gameAssets.countdownText.anchor.set(0.5)
  gameAssets.countdownText.scale.set(1.5 * appAspectRatioX)
  //gameAssets.countdownText.position.set(appWidth * 0.5, appHeight * 0.32)
   containers.loadingContainer.addChild(gameAssets.countdownText)

  gameAssets.loadingBar1 = spriteSettings(gameAssets.loadingBar1, PIXI.Assets.get("loaderBg"), containers.loadingContainer)


  gameAssets.loadingBar2 = spriteSettings(gameAssets.loadingBar2, PIXI.Assets.get("loaderFiller"), containers.loadingContainer)


  gameAssets.loadingBar2.width = 0

  containers.loadingContainer.visible = true

  if (!window.matchMedia("(orientation: portrait)").matches) {
    gameAssets.loadingText.scale.set(getAspectratio() * 0.65)
    // gameAssets.loadingText.position.set(gameContainer.offsetWidth * 0.5, gameAssets.loadingText.height*0.5)
    gameAssets.loadingText.position.set(gameContainer.offsetWidth * 0.4, gameAssets.loadingText.height*0.5)

    gameAssets.loadingBar1.scale.set(1.5 * getAspectratio())
    // gameAssets.loadingBar1.position.set(gameContainer.offsetWidth * 0.5, gameAssets.countdownText.position.y + gameAssets.loadingBar1.height*2)
    gameAssets.loadingBar1.position.set(appWidth * 0.28 + gameAssets.loadingBar1.width*0.5, gameAssets.loadingText.position.y + gameAssets.loadingBar1.height * 2)

    gameAssets.loadingBar2.scale.set(1 * getAspectratio(), 1.5 * getAspectratio())
    gameAssets.loadingBar2.anchor.set(0)
    gameAssets.loadingBar2.position.set(gameAssets.loadingBar1.position.x - gameAssets.loadingBar1.width * 0.48,
      gameAssets.loadingBar1.position.y - gameAssets.loadingBar1.height * 0.3)

    // gameAssets.countdownText.position.set(gameContainer.offsetWidth * 0.5, gameAssets.loadingText.position.y+gameAssets.countdownText.height*0.75)
    gameAssets.countdownText.scale.set(1 * getAspectratio())
    gameAssets.countdownText.position.set(gameAssets.loadingBar1.x - gameAssets.loadingBar1.width*0.5 - gameAssets.countdownText.width*0.8, gameAssets.loadingText.position.y + gameAssets.countdownText.height * 0.88)

  } else {
    gameAssets.loadingText.scale.set(appAspectRatioX * 0.5)
    gameAssets.loadingText.position.set(appWidth * 0.5, appHeight * 0.1)
    gameAssets.loadingText.text = 'WAITING \n FOR NEXT ROUND'

    gameAssets.countdownText.position.set(appWidth*0.5 - gameAssets.countdownText.width*3, gameAssets.loadingText.position.y+gameAssets.countdownText.height*0.7)
    gameAssets.countdownText.scale.set(appAspectRatioX)


    gameAssets.loadingBar1.scale.set(1.5 * appAspectRatioX)
    gameAssets.loadingBar1.position.set(appWidth * 0.5+gameAssets.loadingBar1.width*0.1, gameAssets.loadingText.position.y + gameAssets.loadingBar1.height*2.5)


    gameAssets.loadingBar2.scale.set(0.9 * appAspectRatioX, 1.7 * appAspectRatioX)
    gameAssets.loadingBar2.anchor.set(0)
    gameAssets.loadingBar2.position.set(gameAssets.loadingBar1.position.x - gameAssets.loadingBar1.width * 0.48,
      gameAssets.loadingBar1.position.y - gameAssets.loadingBar1.height * 0.325)

  }


  // if (!window.matchMedia("(orientation: portrait)").matches) {
  //   gameAssets.loadingText.scale.set(appAspectRatioX * 0.7)
  //   gameAssets.loadingText.position.set(appWidth * 0.27, appHeight * 0.1)

  //   gameAssets.loadingBar1.scale.set(2 * appAspectRatioX)
  //   gameAssets.loadingBar1.position.set(appWidth * 0.7, appHeight * 0.1)

  //   gameAssets.loadingBar2.scale.set(1 * appAspectRatioX, 2 * appAspectRatioX)
  //   gameAssets.loadingBar2.anchor.set(1)
  //   gameAssets.loadingBar2.position.set(gameAssets.loadingBar1.position.x + gameAssets.loadingBar1.width * 0.48,
  //     gameAssets.loadingBar1.position.y + gameAssets.loadingBar1.height * 0.3)

  // } else {
  //   gameAssets.loadingText.scale.set(appAspectRatioX * 0.7)
  //   gameAssets.loadingText.position.set(appWidth * 0.5, appHeight * 0.1)
  //   gameAssets.loadingText.text = 'Waiting \n For Next Round'

  //   gameAssets.loadingBar1.scale.set(2 * appAspectRatioX)
  //   gameAssets.loadingBar1.position.set(appWidth * 0.5, appHeight * 0.2)

  //   gameAssets.loadingBar2.scale.set(1 * appAspectRatioX, 2 * appAspectRatioX)
  //   gameAssets.loadingBar2.anchor.set(1)
  //   gameAssets.loadingBar2.position.set(gameAssets.loadingBar1.position.x + gameAssets.loadingBar1.width * 0.48,
  //     gameAssets.loadingBar1.position.y + gameAssets.loadingBar1.height * 0.3)

  // }



}

function createCashoutPopup() {
  gameAssets.cashedoutText1 = new PIXI.Text('YOU HAVE CASHED OUT', new PIXI.TextStyle({
    fontFamily: gameAssets.fonts.ultraRegular.fontFamily,
    fill: ['#004B47'],
    fontSize: 80
  }))

  containers.centerTextContainer.addChild(gameAssets.cashedoutText1)
  gameAssets.cashedoutText1.anchor.set(0.5)
  gameAssets.cashedoutText1.scale.set(0.7 * appAspectRatioX)



  gameAssets.cashedoutText2 = new PIXI.Text('$17.6', new PIXI.TextStyle({
    fontFamily: gameAssets.fonts.ultraRegular.fontFamily,
    fill: ['#004B47'],
    fontSize: 80
  }))

  containers.centerTextContainer.addChild(gameAssets.cashedoutText2)
  gameAssets.cashedoutText2.anchor.set(0.5)
  gameAssets.cashedoutText2.scale.set(1 * appAspectRatioX)



  gameAssets.cashedoutText3 = new PIXI.Text('MULTIPLIER X1.35', new PIXI.TextStyle({
    fontFamily: gameAssets.fonts.ultraRegular.fontFamily,
    fill: ['#004B47'],
    fontSize: 80
  }))

  containers.centerTextContainer.addChild(gameAssets.cashedoutText3)
  gameAssets.cashedoutText3.anchor.set(0.5)
  gameAssets.cashedoutText3.scale.set(0.4 * appAspectRatioX)



  if (window.matchMedia("(orientation: portrait)").matches) {
    // Mobile
    gameAssets.cashedoutText1.position.set(appWidth * 0.5, appHeight * 0.3)
    gameAssets.cashedoutText2.position.set(appWidth * 0.5,gameAssets.cashedoutText1.position.y + gameAssets.cashedoutText1.height*0.5 + gameAssets.cashedoutText2.height*0.5)
    gameAssets.cashedoutText3.position.set(appWidth * 0.5, gameAssets.cashedoutText2.position.y +gameAssets.cashedoutText2.height*0.5 + gameAssets.cashedoutText3.height*0.5)
  } else {
    // Desktop
    gameAssets.cashedoutText1.position.set(appWidth * 0.5, appHeight * 0.15)
    gameAssets.cashedoutText2.position.set(appWidth * 0.5, appHeight * 0.25)
    gameAssets.cashedoutText3.position.set(appWidth * 0.5, appHeight * 0.35)
  }


}

function toggleAnimation(toggle) {
  containers.rocketContainer.visible = toggle
  containers.graphContainer.visible = toggle
  containers.dummyPlanesContainer.visible = toggle
}

function createAxis() {
  const dotRadius = 1.5;
  const dotsNumberX = 20;
  const dotsNumberY = 11;
  const xDotColor = 0xFFFFFF;
  const yDotColor = 0xFFFFFF;

  let dotsDistanceX = appWidth / dotsNumberX;
  let dotsDistanceY = appHeight / dotsNumberY;

  // x-axis
  for (let i = 0; i < dotsNumberX; i++) {
    xGraphic = new PIXI.Graphics();
    containers.axisContainer.addChild(xGraphic);
    xGraphic.position.set(dotsDistanceX * i + appWidth * 0.015, appHeight * 0.98);
    xGraphic.beginFill(xDotColor);
    xGraphic.drawCircle(0, 0, dotRadius);
    gameAssets.axisDots.x.push(xGraphic);
    xGraphic.endFill();
  }
  // y-axis
  for (let i = 0; i < dotsNumberY; i++) {
    yGraphic = new PIXI.Graphics();
    containers.axisContainer.addChild(yGraphic);
    yGraphic.position.set(appWidth * 0.015, dotsDistanceY * i);
    yGraphic.beginFill(yDotColor);
    yGraphic.drawCircle(0, 0, dotRadius);
    gameAssets.axisDots.y.push(yGraphic);
    yGraphic.endFill();
  }


  function distance(p1, p2) {
    return Math.hypot(p2.x - p1.x, p2.y - p1.y)
  }


  const line = new PIXI.Graphics()
  containers.axisContainer.addChild(line)
  // line.position.set(150)
  const p1 = {
    x: appWidth * 0.03,
    y: appHeight * 0.96
  }
  const p2 = {
    x: appWidth,
    y: appHeight * 0.96
  }
  line.lineStyle(0.01, 0x000000)
  line.moveTo(p1.x, p1.y).lineTo(p2.x, p2.y)
  const dash = 5
  const gap = 5
  const len = distance(p1, p2)
  const norm = {
    x: (p2.x - p1.x) / len,
    y: (p2.y - p1.y) / len
  }
  line.lineStyle(0.2, 0xFFFFFF)
  line.moveTo(p1.x, p1.y).lineTo(p1.x + dash * norm.x, p1.y + dash * norm.y)
  var progress = dash + gap
  while (progress < len) {
    line.moveTo(p1.x + progress * norm.x, p1.y + progress * norm.y)
    progress += dash
    line.lineTo(p1.x + progress * norm.x, p1.y + progress * norm.y)
    progress += gap

    // console.log("X= "+(p1.x+progress*norm.x)+" ,Y= "+ p1.y+progress*norm.y)
  }


  const line2 = new PIXI.Graphics()
  containers.axisContainer.addChild(line2)
  // line.position.set(150)
  const p12 = {
    x: appWidth * 0.027,
    y: appHeight * 0
  }
  const p22 = {
    x: appWidth * 0.027,
    y: appHeight * 0.952
  }
  line2.lineStyle(0.01, 0x000000)
  line2.moveTo(p12.x, p12.y).lineTo(p22.x, p22.y)
  const dash2 = 5
  const gap2 = 5
  const len2 = distance(p12, p22)
  const norm2 = {
    x: (p22.x - p12.x) / len2,
    y: (p22.y - p12.y) / len2
  }
  line2.lineStyle(0.2, 0xFFFFFF)
  line2.moveTo(p12.x, p12.y).lineTo(p12.x + dash2 * norm2.x, p12.y + dash2 * norm2.y)
  var progress2 = dash2 + gap2
  while (progress2 < len2) {
    line2.moveTo(p12.x + progress2 * norm2.x, p12.y + progress2 * norm2.y)
    progress2 += dash2
    line2.lineTo(p12.x + progress2 * norm2.x, p12.y + progress2 * norm2.y)
    progress2 += gap2

    //  console.log("X= "+(p12.x+progress2*norm2.x)+" ,Y= "+ p12.y+progress2*norm2.y)
  }

}

function setCenterText(s) {
  UI.gameAssets.centerBigText.text = s
}

function createDummyRockets() {
  gameAssets.nearestPlane = PIXI.Sprite.from('nearestPlane')
  gameAssets.nearestPlane.scale.set(1.25 * appAspectRatio)

  // let scale = getScale(
  //   gameAssets.rocket.width, gameAssets.rocket.height,
  //   window.innerWidth, window.innerHeight,
  //   scalingPercentage.desktop.mainPlane / 100  // this much of area will be taken by source
  // )
  // gameAssets.nearestPlane.scale.set(scale)
  gameAssets.nearestPlane.anchor.set(0.5)

  gameAssets.farthestPlane = PIXI.Sprite.from('farthestPlane')
  gameAssets.farthestPlane.scale.set(1.25 * appAspectRatio)
  gameAssets.farthestPlane.anchor.set(0.5)

  containers.dummyPlanesContainer.addChild(
    gameAssets.nearestPlane,
    gameAssets.farthestPlane
  )

  gameAssets.nearestPlane.position.set(
    0 - gameAssets.nearestPlane.width,
    0 - gameAssets.nearestPlane.height
  )

  gameAssets.farthestPlane.position.set(
    0 - gameAssets.farthestPlane.width,
    0 - gameAssets.farthestPlane.height
  )

  if (window.matchMedia("(orientation: portrait)").matches) {
    gameAssets.nearestPlane.scale.set(0.25 * appAspectRatio)
    gameAssets.farthestPlane.scale.set(0.25 * appAspectRatio)
  }
}

function createRocket() {
  // ------ Assets got replaced! ------
  // gameAssets.rocketTextures[0] = PIXI.Assets.get("allAssets").textures.char1
  // gameAssets.rocketTextures[1] = PIXI.Assets.get("allAssets").textures.char2
  // gameAssets.rocketTextures[2] = PIXI.Assets.get("allAssets").textures.char3

  gameAssets.rocket = spriteSettings(gameAssets.rocket, PIXI.Assets.get("mainPlain"), containers.rocketContainer)


  if (window.matchMedia("(orientation: portrait)").matches) {
    // Mobile
    gameAssets.rocket.scale.set(0.75 * appAspectRatioX)
  } else {
    // Desktop
    gameAssets.rocket.scale.set(1.1 * appAspectRatioX)
  }

  const explosion = PIXI.Assets.get('explosion')
  gameAssets.explosionAnim = new PIXI.AnimatedSprite(explosion.animations.explode)
  gameAssets.explosionAnim.anchor.set(0.5)
  gameAssets.explosionAnim.scale.set(0.75 * appAspectRatio)
  gameAssets.explosionAnim.animationSpeed = 0.1
  gameAssets.explosionAnim.loop = false
  gameAssets.explosionAnim.visible = false

  containers.rocketContainer.addChild(gameAssets.explosionAnim)

  if (window.matchMedia("(orientation: portrait)").matches) {
    gameAssets.rocket.scale.set(0.3 * appAspectRatio)
    gameAssets.explosionAnim.scale.set(0.25 * appAspectRatio)
  }
}

function createGraph() {
  // gameAssets.bottomGradient = spriteSettings(gameAssets.bottomGradient, PIXI.Assets.get("allAssets").textures.graphGrad, containers.bgContainer)
  // gameAssets.bottomGradient.width = appWidth
  // gameAssets.bottomGradient.height = appHeight
  // gameAssets.bottomGradient.position.set(appWidth * 0.5, appHeight * 0.5)

  //  gameAssets.bottomGradient.mask = gameAssets.bottomLineGraph

  //  containers.graphContainer.addChild(gameAssets.bottomGradient)
  containers.graphContainer.addChild(gameAssets.bottomLineGraph)
  containers.graphContainer.addChild(gameAssets.topLineGraph)
  containers.graphContainer.position.set(appWidth * 0.025, -appHeight * 0.04)
}

function createCenterText() {
  gameAssets.centerBigText = new PIXI.Text('', new PIXI.TextStyle({
    fontFamily: gameAssets.fonts.ultraRegular.fontFamily,
    fill: ['#48A29C'],
    fontSize: 80
  }))

  gameAssets.centerBigText.anchor.set(0.5)
  if (window.matchMedia("(orientation: portrait)").matches) {
    // Mobile
    gameAssets.centerBigText.scale.set(1 * appAspectRatioX)
  } else {
    // Desktop
    gameAssets.centerBigText.scale.set(1.4 * appAspectRatioX)
  }
 // gameAssets.centerBigText.position.set(appWidth * 0.85, gameAssets.centerBigText.height)



  gameAssets.centerSmallText = new PIXI.Text('CRASHED', new PIXI.TextStyle({
    fontFamily: gameAssets.fonts.ultraRegular.fontFamily,
    fill: ['#A92A4E'],
    fontSize: 90
  }))

  containers.centerTextContainer.addChild(gameAssets.centerSmallText, gameAssets.centerBigText)
  gameAssets.centerSmallText.anchor.set(0.5)
  gameAssets.centerSmallText.scale.set(1 * appAspectRatioX)


  gameAssets.centerSmallText.position.set(
    gameAssets.centerBigText.x - gameAssets.centerBigText.width,
    gameAssets.centerBigText.y
  )

  gameAssets.crashCenterText = new PIXI.Text('TAKEN OFF!', new PIXI.TextStyle({
    fontFamily: gameAssets.fonts.semiBold.fontFamily,
    fill: ['#FFFFFF'],
    fontSize: 100,
    align: 'center',
  }))
  gameAssets.crashCenterText.anchor.set(0.5)
  gameAssets.crashCenterText.scale.set(appAspectRatioX * 0.9)
  gameAssets.crashCenterText.position.set(appWidth * 0.5, appHeight * 0.5)
  containers.centerTextContainer.addChild(gameAssets.crashCenterText)

  if (window.matchMedia("(orientation: portrait)").matches) {
   // gameAssets.centerBigText.position.set(appWidth * 0.5, gameAssets.centerBigText.height)
    // gameAssets.centerSmallText.position.set(
    //   gameAssets.centerBigText.x,
    //   gameAssets.centerBigText.y
    //   //+ gameAssets.centerBigText.height * 1.25
    // )
    gameAssets.centerBigText.style.fontSize = 120
  }

  gameAssets.crashCenterText.visible = false
}


export {
  toggleAnimation,
  containers,
  gameAssets,
  createUI,
  uiCreated,
  appHeight,
  appWidth,
  setCenterText
}
