import React from 'react'
import './CrashSubSettingsModale.scss'
import Popup from 'components/ui-kit/Popup/index'
import Button from 'components/ui-kit/Button/index'
import { CrossIcon, UsersFillIcon } from 'assets/icons/index'

const CrashSubSettingsModal = ({
  setIsOpenSubSettingsModal,
  isOpenSubSettingsModal
}) => {
  return (
    <Popup className='crash-sub-settings-modal' isOpenModal={isOpenSubSettingsModal}>
      <Button className='btn-close-modal p-0' onClick={() => setIsOpenSubSettingsModal(false)}>
        <div>
          <CrossIcon />
        </div>
      </Button>
      <div className='modal-head d-flex align-items-center'>
        <UsersFillIcon className='m-1 users-head-icon' />Sub Settings
      </div>
      <div className='content-body' />
    </Popup>
  )
}
export default CrashSubSettingsModal
