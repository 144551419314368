import axiosInstanceService, { microServices } from 'network/apis/index'
import { METHOD_TYPES } from 'utils/constants/index'

export const placeBetRouletteGameService = ({ ...data }) => {
  return axiosInstanceService(METHOD_TYPES.post, 'roulette-game/place-bet', data, {
    server: microServices.USER_URL
  })
}

export const getMyBetsRouletteService = (params) => {
  return axiosInstanceService(METHOD_TYPES.get, 'roulette-game/my-bets', {}, {
    params,
    server: microServices.USER_URL
  })
}

export const rouletteCheckFairnessService = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, 'roulette-game/check-fairness', data, {
    server: microServices.SERVICE_URL_1
  })
}
