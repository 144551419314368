import Matter from 'matter-js'
import { assets, matterAssets, dataAnalysis, testIndex, linesNum } from './gameUI.js'
import { settings } from '../settings.js'
import { playLeftDropSound, playRightDropSound, playSoundForBoxDrop } from '../soundManager.js'
import { dispatchEveryBallTouch, dispatching } from '../bridge.js'
import { path } from './ball.js'
// import { addMultiplier } from 'games/PlinkoGame/slice-thunk/plinkoGame.slice.js'
// import store from 'redux-store/store.js'

let boxInd
export const setupMatterJS = () => {
  matterAssets.world = matterAssets.engine.world
  matterAssets.engine.timing.isFixed = true
  matterAssets.engine.timing.fixedDeltaTime = 16.67 // 60 FPS (in milliseconds)

  // const runner = Matter.Runner.create()
  // runner.isFixed = true

  // const runner = Matter.Runner.create()
  // runner.isFixed = true

  matterAssets.world.gravity.y = settings.matter.gravity
  Matter.Events.on(matterAssets.engine, 'collisionStart', event => {
    let box
    event.pairs.forEach(pair => {
      const { bodyA, bodyB } = pair

      if ((bodyA.label === 'Ball' && bodyB.label === 'Point') || (bodyA.label === 'Point' && bodyB.label === 'Ball')) {
        const pointBody = bodyA.label === 'Point' ? bodyA.parent : bodyB.parent
        if (pointBody) {
          // console.log(pointBody.pointRow, pointBody.pointNumber)
          assets.points[pointBody.pointRow][pointBody.pointNumber].pixiBody.startShine()
        }

        const ballBody = bodyA.label === 'Ball' ? bodyA.parent : bodyB.parent
        if (ballBody) {
          path(ballBody, pointBody)
          // console.log(pointBody.pointRow, pointBody.pointNumber)
          if (ballBody.position.x > ballBody.prevPointX) playRightDropSound()
          else playLeftDropSound()

          ballBody.prevPointX = ballBody.position.x
        }
      }
      let fault = false
      if ((bodyA.label === 'Ball' && bodyB.label === 'Box') || (bodyA.label === 'Box' && bodyB.label === 'Ball')) {
        const boxBody = bodyA.label === 'Box' ? bodyA.parent : bodyB.parent
        if (boxBody) {
          //   store.dispatch(addMultiplier())

          playSoundForBoxDrop(boxBody.boxIndex, linesNum.get() + 1)
          // console.log(assets.boxes[boxBody.boxIndex])
          box = assets.boxes[boxBody.boxIndex].boxContainer
          if (box) {
            // console.log(box.y)
            box.y = box.startPos + 40
            box.boxState = box.boxBounceEffect
            boxInd = assets.boxes[boxBody.boxIndex].boxMatter.boxIndex
            // console.log('boxtestIndex', testIndex)
            // console.log(assets.boxes[boxBody.boxIndex].boxMatter.boxIndex)
            if (assets.boxes[boxBody.boxIndex].boxMatter.boxIndex !== testIndex) {
              fault = true
              // console.log('box', assets.boxes[boxBody.boxIndex].boxMatter.boxIndex)
            }
          } else console.error('Box not identified')
        }

        const ballBody = bodyA.label === 'Ball' ? bodyA.parent : bodyB.parent
        if (ballBody) {
          const ballObject = assets.activeBalls.find(ball => ball.matterBody === ballBody)
          if (ballObject && typeof ballObject.destroy === 'function') {
            // dataAnalysis.push({
            //   initialX: ballBody.initialX,
            //   boxIndex: boxBody.boxIndex
            // })
            dataAnalysis[linesNum.get() - settings.lines.initialNumber][boxInd].push(ballBody.initialX)
            boxInd = null
            if (fault) {
              // console.log('fault ball initialX', ballBody.initialX)
              // alert(ballBody.initialX)
              fault = false
            }
            ballObject.destroy()
            // console.log("dispatching")
            dispatchEveryBallTouch()
            if (assets.activeBalls.length - 1 < 0) dispatching()
            // console.log(assets.activeBalls.length - 1)
          }
        }
      }
    })
  })
}

export const matterEngineIgnition = () => Matter.Engine.run(matterAssets.engine)

export const destroyMatterWorld = () => new Promise((resolve, reject) => {
  Matter.World.clear(matterAssets.world, true)
  Matter.Engine.clear(matterAssets.engine)
  resolve()
})
