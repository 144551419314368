import { DEFAULT_PRECISION_MONEY } from 'utils/constants/index'

export const formatDate = (originalDateString) => {
  const date = new Date(originalDateString)

  const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ]
  const day = date.getDate()
  const monthIndex = date.getMonth()
  const year = date.getFullYear()
  const formattedDate = `${day} ${months[monthIndex]} ${year}`
  return formattedDate
}

export const formatTime = (originalDateString) => {
  const date = new Date(originalDateString)

  const hours = date.getHours()
  const minutes = date.getMinutes()

  const ampm = hours >= 12 ? 'PM' : 'AM'
  const formattedHours = hours % 12 || 12 // Convert 0 to 12 for 12 AM

  const formattedTime = `${formattedHours}:${minutes < 10 ? '0' : ''}${minutes} ${ampm}`

  return formattedTime
}

/**
 * Parses payload object from jwt
 * @param {string} token
 * @returns {Object}
 */
export const getPayloadFromToken = (token) => {
  if (token) {
    return JSON.parse(atob(token.split('.')[1]))
  }
}

export const generateClientSeed = () => {
  const availableChars = '0123456789abcdef'
  let seed = ''
  for (let i = 0; i < 32; i++) {
    seed += availableChars[Math.floor(Math.random() * availableChars.length)]
  }
  return seed
}

/**
 *
 * @param {number} value
 * @param {number} precision
 * @returns {number | string} Returns precision value for specified decimal places
 */
export const getPrecision = (value, precision = DEFAULT_PRECISION_MONEY) => {
  const precisionDivide = 10 ** precision
  const result = parseInt(value * precisionDivide) / precisionDivide
  return result || 0
}

/**
 * To convert a numeric value to standard precision
 * @param {number} amount amount to be converted to precision
 * @param {number} precision digits to be considered for precision
 * @returns {number}
 */
export const getPrecisionNumber = (amount, precision = DEFAULT_PRECISION_MONEY) => {
  const precisionDivide = 10 ** precision
  const result = parseInt(amount * precisionDivide) / precisionDivide || 0
  return result
}

/**
 *
 * @param {number} x
 * @returns {string} valid million based comma value in string
 */
export const numberWithCommas = (x) => {
  if (x === null || x === undefined || Number(x) === Number.isNaN) {
    return ''
  }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

/**
 * Durstenfeld array shuffling algorithm
 * @param {number} array
 */
export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]]
  }
  return array
}

export const cartesianProductOfArrays = (...a) => a.reduce((a, b) => a.flatMap(d => b.map(e => [d, e].flat())))

export function delay (ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}
