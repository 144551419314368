import { settings } from './settings.js'
import * as PIXI from 'pixi.js'

export const app = new PIXI.Application({
  width: settings.originalWidth,
  height: settings.originalHeight,
  // backgroundColor: 0x000000,
  antialias: true,
  // resolution: window.devicePixelRatio,
  backgroundAlpha: 0
})

globalThis.__PIXI_APP__ = app

function appTransform (x, y) {
  // app.view.style.width = x + "px";
  // app.view.style.height = y + "px";
// app.renderer.resize(x,y)
}

appTransform(50, 50)
