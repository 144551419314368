import axiosInstance, { microServices } from 'network/apis/index'
import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants/index'

export const loginService = (data) => {
  return axiosInstance(METHOD_TYPES.post, 'user/login', data, {
    server: microServices.USER_URL,
    handlerEnabled: false,
    loader: LOADER_HANDLER_TYPES.submit
    // successMessage: SuccessMessage.login
  })
}

export const gameCloseService = () => {
  return axiosInstance(METHOD_TYPES.post, 'user/game-close', {}, {
    server: microServices.USER_URL,
    handlerEnabled: false,
    loader: LOADER_HANDLER_TYPES.submit
    // successMessage: SuccessMessage.gameClose
  })
}
