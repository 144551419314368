import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  blackJackCheckFairness,
  blackJackGameDoubleBet,
  blackJackGameHitBet,
  blackJackGamePlaceBet,
  blackJackGameSplitBet,
  blackJackGameStandBet,
  blackJackPlaceInsuranceBet,
  blackJackUnfinishedBet,
  blackJackUpdateCurrentHandStatus,
  getBlackJackGameBetHistory,
  getBlackJackHighRollerTransactionsService,
  getBlackJackTransactionsService,
  getMyBlackJackTransactionsService
} from 'pages/BlackjackGame/blackjack.service'
import { openErrorToaster, openSuccessToaster } from 'utils/common-services/toaster.service'
// import { openErrorToaster, openSuccessToaster } from 'helpers/toaster.helpers'

export const placedBetBlackjackGame = createAsyncThunk('blackjack-game/place-bet-blackjack', async ({ payload }, thunkApi) => {
  try {
    const res = await blackJackGamePlaceBet(payload)
    // thunkApi.dispatch(setPlacedCrashBetId(res?.id))
    openSuccessToaster({ message: 'Bet Placed successfully' })
    return res
  } catch (error) {
    // openErrorToaster({ message: error[0].description })
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const hitBetBlackjackGame = createAsyncThunk('blackjack-game/hit-bet-blackjack', async ({ payload }, thunkApi) => {
  try {
    const res = await blackJackGameHitBet(payload)
    // thunkApi.dispatch(setPlacedCrashBetId(res?.id))
    // openSuccessToaster({ message: 'Bet Placed successfully' })
    return res
  } catch (error) {
    openErrorToaster({ message: error[0].description })
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const standBetBlackjackGame = createAsyncThunk('blackjack-game/stand-bet-blackjack', async ({ payload }, thunkApi) => {
  try {
    const res = await blackJackGameStandBet(payload)
    // thunkApi.dispatch(setPlacedCrashBetId(res?.id))
    // openSuccessToaster({ message: 'Bet Placed successfully' })
    return res
  } catch (error) {
    openErrorToaster({ message: error[0].description })
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const splitBetBlackjackGame = createAsyncThunk('blackjack-game/split-bet-blackjack', async ({ payload }, thunkApi) => {
  try {
    const res = await blackJackGameSplitBet(payload)
    // thunkApi.dispatch(setPlacedCrashBetId(res?.id))
    // openSuccessToaster({ message: 'Bet Placed successfully' })
    return res
  } catch (error) {
    openErrorToaster({ message: error[0].description })
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const doubleBetBlackjackGame = createAsyncThunk('blackjack-game/double-bet-blackjack', async ({ payload }, thunkApi) => {
  try {
    const res = await blackJackGameDoubleBet(payload)
    // thunkApi.dispatch(setPlacedCrashBetId(res?.id))
    // openSuccessToaster({ message: 'Bet Placed successfully' })
    return res
  } catch (error) {
    openErrorToaster({ message: error[0].description })
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const betListBlackjackGame = createAsyncThunk('blackjack-game/double-bet-blackjack', async ({ params }, thunkApi) => {
  try {
    const res = await getBlackJackGameBetHistory(params)
    // thunkApi.dispatch(setPlacedCrashBetId(res?.id))
    // openSuccessToaster({ message: 'Bet Placed successfully' })
    return res
  } catch (error) {
    openErrorToaster({ message: error[0].description })
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const unfinishedBetBlackjackGame = createAsyncThunk('blackjack-game/unfinished-bet-blackjack', async (_, thunkApi) => {
  try {
    const res = await blackJackUnfinishedBet()
    // thunkApi.dispatch(setPlacedCrashBetId(res?.id))
    // openSuccessToaster({ message: 'Bet Placed successfully' })
    return res
  } catch (error) {
    openErrorToaster({ message: error[0].description })
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const placeInsuranceBlackjackGame = createAsyncThunk('blackjack-game/place-insurance', async ({ payload }, thunkApi) => {
  try {
    const res = await blackJackPlaceInsuranceBet(payload)
    // thunkApi.dispatch(setPlacedCrashBetId(res?.id))
    // openSuccessToaster({ message: 'Bet Placed successfully' })

    return res
  } catch (error) {
    openErrorToaster({ message: error[0].description })
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const updateCurrentStatusBlackjackGame = createAsyncThunk('blackjack-game/update-current-hand-status', async ({ payload }, thunkApi) => {
  try {
    const res = await blackJackUpdateCurrentHandStatus(payload)
    // thunkApi.dispatch(setPlacedCrashBetId(res?.id))
    // openSuccessToaster({ message: 'Bet Placed successfully' })
    return res
  } catch (error) {
    openErrorToaster({ message: error[0].description })
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const checkFairnessBlackjackGame = createAsyncThunk('blackjack-game/check-fairness', async (payload, thunkApi) => {
  try {
    const res = await blackJackCheckFairness(payload)
    // thunkApi.dispatch(setPlacedCrashBetId(res?.id))
    // openSuccessToaster({ message: 'Bet Placed successfully' })
    return res
  } catch (error) {
    openErrorToaster({ message: error[0].description })
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const getBlackJackHighRollerTransactions = createAsyncThunk(
  'fetch/blackjack-transactions-high-roller',
  async (data, thunkApi) => {
    try {
      const res = await getBlackJackHighRollerTransactionsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getMyBlackJackTransactions = createAsyncThunk(
  'fetch/blackjack-game-transactions',
  async (data, thunkApi) => {
    try {
      const res = await getMyBlackJackTransactionsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getBlackJackTransactions = createAsyncThunk(
  'fetch/get-blackjack-transactions',
  async (data, thunkApi) => {
    try {
      const res = await getBlackJackTransactionsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
