let currentPixiApp = null
export let pixiApp

const loadAssetsPixi = (setIsPixiAssetsLoaded) =>
  import('./assetsLoad.js')
    .then(({ default: loadAssets }) => {
      return loadAssets(setIsPixiAssetsLoaded)
    })
    .catch((error) => {
      console.error('Error during assets loading in Pixi:', error)
      throw error
    })

const loadPixiApp = () =>
  import('./appPixi.js')
    .then(({ default: createPixiApp }) => {
      if (currentPixiApp) {
        currentPixiApp.destroyPixi(true)
      }

      pixiApp = createPixiApp()
      currentPixiApp = pixiApp

      console.log('Pixi app loaded!')
      return pixiApp
    })
    .catch((error) => {
      console.error('Error during Pixi.js initialization:', error)
      throw error
    })

const loadGameUI = () =>
  import('./gameUI.js')
    .then(({ createUI }) => {
      createUI()
      console.log('Game UI created!')
    })
    .catch((error) => {
      console.error('Error loading gameUI.js:', error)
      throw error
    })

const gameTickerStart = () =>
  import('./gameLogic.js')
    .then(({ startGameTicker }) => {
      startGameTicker()
      console.log('Game Ticker Started!')
    })
    .catch((error) => {
      console.error('Error loading gameLogic.js:', error)
      throw error
    })

export const loadPixiAssets = () =>
  loadAssetsPixi()
    .then(() => console.log())
    .catch((error) => {
      console.error('Error loading pixi assets:', error)
    })

export const destroyPixiApp = () => {
  if (currentPixiApp) {
    currentPixiApp.destroyPixi(true)
    currentPixiApp = null
  }
}

export const init = (setIsPixiAssetsLoaded) =>
  loadAssetsPixi(setIsPixiAssetsLoaded)
    .then(() => loadPixiApp())
    .then(() => currentPixiApp.resizePixiApp(2000))
    .then(() => loadGameUI())
    .then(() => gameTickerStart())
    .catch((error) => {
      console.error('Error during Pixi initialization:', error)
    })

let gameUIModule

export const loadGameUIFunctions = () => {
  if (!gameUIModule) {
    return import('./gameUI.js')
      .then((module) => {
        gameUIModule = module
        return gameUIModule
      })
      .catch((error) => {
        console.error('Error loading GameUI.js:', error)
        throw error
      })
  }

  return Promise.resolve(gameUIModule)
}
