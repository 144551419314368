import React, { useState } from 'react'
import './ProvablyFair.scss'
import Button from 'components/ui-kit/Button/index'
import { CrossIcon } from 'assets/icons/index'
import Popup from 'components/ui-kit/Popup/index'
import { useDispatch, useSelector } from 'react-redux'
import { verifyProvableFairCrash } from 'redux-store/thunk/crashGame.thunk'
import { openErrorToaster } from 'utils/common-services/toaster.service'
import { setProvableFairData } from 'redux-store/redux/slices/crashGame.slice'

const ProvablyFair = ({
  setIsOpenProvablyFair,
  isOpenProvablyFair,
  playClickSound
}) => {
  const dispatch = useDispatch()
  const { provableFairData, loading } = useSelector(state => state?.crashGame)
  const [signature, setSignature] = useState('')
  const [hash, setHash] = useState('')

  const handleVerifyGame = () => {
    playClickSound()
    if (hash === '') {
      openErrorToaster({ message: 'Round Hash is Required' })
    } else if (signature === '') {
      openErrorToaster({ message: 'Signature is Required' })
    } else {
      dispatch(verifyProvableFairCrash({ payload: { roundHash: hash, signature: signature } }))
    }
  }

  const handleClose = () => {
    setIsOpenProvablyFair(false)
    setSignature('')
    setHash('')
    dispatch(setProvableFairData(null))
  }
  return (
    <Popup className='provably-fair-modal' isOpenModal={isOpenProvablyFair}>
      <Button className='btn-outline-primary btn-close-modal p-0' onClick={handleClose}>
        <div>
          <CrossIcon />
        </div>
      </Button>
      <div className='text-center text-white py-1'>
        <div className='provably-fair-title'>
          <h5 className='mb-3 pb-4 border-bottom'>Check Provably Fair</h5>
        </div>
        <div className='text-start'>
          <p className='fw-normal'>
            This game uses Provably Fair technology to determine game result. This tool gives you ability to change your seed and check fairness of the game.
          </p>
          {provableFairData &&
            <div className='mb-3'>
              <label htmlFor='cashRate' className='form-label form-label-18'>Crash Rate</label>
              <input type='text' className='form-control' value={provableFairData?.crashRate} id='cashRate' placeholder='Crash rate without x' disabled />
            </div>}
          <div className='mb-3'>
            <label htmlFor='roundHas' className='form-label form-label-18'>Round Hash</label>
            <input type='text' value={hash} className='form-control' id='roundHash' placeholder='e.g. 0x00000' onChange={(e) => setHash(e.target.value)} />
          </div>
          <div className='mb-3'>
            <label htmlFor='roundHas' className='form-label form-label-18'>Signature</label>
            <input type='text' value={signature} className='form-control' id='signature' placeholder='e.g. 0x00000' onChange={(e) => setSignature(e.target.value)} />
          </div>
        </div>
        {
        loading
          ? <Button className='btn-outline-secondary btn-start-playing mb-4 mt-4 py-2' disabled>Loading</Button>
          : <Button className='btn-outline-secondary btn-start-playing mb-4 mt-4 py-2' onClick={handleVerifyGame}>Verify Game</Button>

}
        <p className='mb-0 mt-3 fw-normal fairness-text'>You can check fairness of each round from round history.</p>
      </div>
    </Popup>
  )
}
export default ProvablyFair
