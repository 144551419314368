import { createSlice } from '@reduxjs/toolkit'
import { getGameSetting } from 'redux-store/thunk/gameSettings.thunk'
import { generateServerSeed } from 'redux-store/thunk/user.thunk'
import { generateClientSeed } from 'utils/common-functions/index'
import { DEFAULT_MUSIC_VOLUME, DEFAULT_SOUND_VOLUME } from 'utils/constants/index'

const initialState = {
  systemGameData: null,
  allCurrencies: [],
  gameSettings: [],
  gameSettingLoader: false,
  clientSeed: generateClientSeed() || '',
  serverSeedHash: '',
  gameMusic: DEFAULT_MUSIC_VOLUME > 0,
  gameMusicVolume: DEFAULT_MUSIC_VOLUME,
  gameSound: DEFAULT_SOUND_VOLUME > 0,
  gameSoundVolume: DEFAULT_SOUND_VOLUME,
  firstInteraction: false
}

const {
  actions: {
    setGameSoundVolume,
    setAllcurrency,
    setGameSettings,
    setGameSettingLoader,
    setNewClientSeed,
    setServerSeedHash,
    setGameMusic,
    updateGameSetting,
    setGameSound,
    setGameMusicVolume,
    setFirstInteraction
  },
  reducer
} = createSlice({
  name: 'gameSetting',
  initialState,
  reducers: {
    setFirstInteraction: (state, action) => {
      return {
        ...state,
        firstInteraction: true
      }
    },
    setGameMusicVolume: (state, action) => {
      return {
        ...state,
        gameMusicVolume: action.payload
      }
    },
    setGameSoundVolume: (state, action) => {
      return {
        ...state,
        gameSoundVolume: action.payload
      }
    },
    setAllcurrency: (state, action) => ({
      ...state,
      allCurrencies: action.payload
    }),
    setGameSettings: (state, action) => ({
      ...state,
      gameSettings: action.payload
    }),
    setGameSettingLoader: (state, action) => ({
      ...state,
      setGameSettingLoader: action.payload
    }),
    setNewClientSeed: (state, action) => ({
      ...state,
      clientSeed: action.payload
    }),
    setServerSeedHash: (state, action) => ({
      ...state,
      serverSeedHash: action.payload
    }),
    setGameMusic: (state, action) => ({
      ...state,
      gameMusic: !state.gameMusic
    }),
    setGameSound: (state, action) => ({
      ...state,
      gameSound: !state.gameSound
    }),
    updateGameSetting: (state, action) => {
      return {
        ...state,
        systemGameData: action.payload
      }
    }

  },
  extraReducers: (builder) => {
    builder
      .addCase(getGameSetting.fulfilled, (state, action) => {
        return {
          ...state,
          systemGameData: action.payload,
          gameSettingLoader: false
        }
      })
      .addCase(generateServerSeed.fulfilled, (state, action) => {
        return {
          ...state,
          serverSeedHash: action.payload?.serverSeedHash ?? ''
        }
      })
      .addCase(generateServerSeed.rejected, (state, action) => {
        return {
          ...state
        }
      })
  }
})

export default reducer
export {
  setGameSoundVolume,
  setAllcurrency,
  setGameSettings,
  setGameSettingLoader,
  setNewClientSeed,
  setGameMusic,
  setServerSeedHash,
  updateGameSetting,
  setGameSound,
  setGameMusicVolume,
  setFirstInteraction
}
