import './rangeSlider.scss'

const RangeSlider = (props) => {
  const percentage = 100 * (props.value - props.min) / (props.max - props.min)

  const rangerStyle = {
    background: `linear-gradient(90deg, var(--orange-200) 0, var(--orange-200) ${percentage}%, ${props.secondaryBgColor ? props.secondaryBgColor : 'rgba(215, 220, 223, 0.4)'} ${percentage + 0.1}%)`
  }

  return (
    <input className={`range-slider-input ${props.customClasses ? props.customClasses : ''}`} style={rangerStyle} type='range' value={props.value} min={props.min} max={props.max} onChange={props.onChange} disabled={props.disabled} />
  )
}

export default RangeSlider
